import { useMutation, useQueries, useQuery } from '@tanstack/react-query'
import {
  deleteNotifications,
  getWhatsappAccount,
  whatsappLogin,
  whatsappLoginControl,
  whatsappLogout,
  whatsappUnreadHeaderMessage,
  whatsappUnreadMessage,
  wpdeviceControl,
} from './service'
export const getDevicesHook = (page: number) => {
  return useQuery(
    ['devices', page],
    async () =>
      await getWhatsappAccount(page)
        .then((res) => res.data.Records)
        .catch((err) => []),
    {
      cacheTime: 1000 * 60 * 60,
      staleTime: 5000 * 60 * 60,
    }
  )
}

export const whatsappLoginHook = () => {
  return useMutation(['whatsappLogin'], async () => await whatsappLogin(), {
    onSuccess: async (res) => {
      return res.data.response
    },
  })
}

export const whatsappLogoutHook = () => {
  return useMutation(
    ['whatsappLogout'],
    async (data: { identifier: string; registration_id: number }) =>
      await whatsappLogout(data),
    {
      onSuccess: async (res) => {
        return res
      },
    }
  )
}
export const whatsappLoginControlHook = () => {
  return useMutation(
    ['whatsappLoginControl'],
    async (deviceId: number) => await whatsappLoginControl(deviceId),
    {
      retry: 5,
      onSuccess: async (res) => {
        return res.data.response
      },
    }
  )
}

export const whatsappMessageUnreadHook = (identifier?: number) => {
  return useQuery(
    ['wpUnreadMessage'],
    async () =>
      await whatsappUnreadMessage(identifier)
        .then((res) => res.data.Records as any)
        .catch((err) => [])
  )
}

export const whatsappMessageUnreadHeaderHook = (page?: number) => {
  const identifier = localStorage.getItem('activeDevice')
  return useQuery(
    ['wpUnreadHeaderMessage', identifier, page],
    async () =>
      await whatsappUnreadHeaderMessage(page, identifier)
        .then((res) => res.data.Records as any)
        .catch((err) => [])
  )
}

export const wpControlHook = () => {
  return useMutation(
    ['login-control'],
    async (registirationId: number) => await wpdeviceControl(registirationId),
    {
      onSuccess: async (res) => {
        return res
      },
    }
  )
}

export const deleteNotificationsAllHook = () => {
  return useMutation(
    ['delete-notifications'],
    async (data: any) => await deleteNotifications(data),
    {
      onSuccess: async (res) => {
        return res
      },
      onError: async (res) => {
        return res
      },
    }
  )
}
