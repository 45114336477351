import { Circle, Link, User } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { FaPencilRuler } from 'react-icons/fa'
import { IoIosPaper } from 'react-icons/io'
import { MdDashboard } from 'react-icons/md'
export default [
  {
    id: 'dashboard',
    title: 'Linklerim',
    icon: <Link size={20} style={{ width: '25px', height: '25px' }} />,
    navLink:'/',
    children: [
      {
        id: 'residentOpt',
        title: 'First Account',
        icon: <Circle size={12} />,
        navLink: '/123',
      },
      {
        id: 'apartmentOpt',
        title: 'Second Account',
        icon: <Circle size={12} />,
        navLink: '/321',
      },
    ]
  },
  {
    id: 'design',
    title: 'Tasarım',
    icon: <FaPencilRuler size={20} style={{ width: '25px', height: '25px' }} />,
    navLink: '/design',
  },
  {
    id: 'raports',
    title: 'Analitik Raporlar',
    icon: <IoIosPaper size={20} style={{ width: '25px', height: '25px' }} />,
    navLink: '/raports',
  },
  {
    id: 'users',
    title: 'Kullanıcı',
    icon: <User size={20} style={{ width: '25px', height: '25px' }} />,
    navLink: '/users',
  },
]
