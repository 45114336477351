import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { Grid, Card, Typography, Button } from '@mui/material';
import { Edit, Trash } from 'react-feather';
import image from '../../../../assets/images/main/wp-background.png';
import { IOSSwitch } from 'src/views/components/IOSSwitch';

interface Item {
    id: string;
    content: string;
}

interface Column {
    id: string;
    title: string;
    items: Item[];
}

interface Columns {
    [key: string]: Column;
}

const initialColumns: Columns = {
    column1: {
        id: 'column1',
        title: 'Fotoğraflar',
        items: [
            { id: '1', content: 'Item 1' },
            { id: '2', content: 'Item 2' },
            { id: '3', content: 'Item 3' },
            { id: '4', content: 'Item 4' },
            { id: '5', content: 'Item 5' },
        ]
    },
    column2: {
        id: 'column2',
        title: 'Galeri',
        items: [
            { id: '13', content: 'Item 13' },
            { id: '14', content: 'Item 14' },
            { id: '15', content: 'Item 15' },
            { id: '16', content: 'Item 16' },
            { id: '17', content: 'Item 17' },
            { id: '18', content: 'Item 18' },
        ]
    }
};

const Index = () => {
    const [columns, setColumns] = useState<Columns>(initialColumns);

    const onDragEndColumn = (result: DropResult, columnId: string) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        if (source.droppableId !== columnId || destination.droppableId !== columnId) {
            return;
        }

        const column = columns[columnId];
        const copiedItems = [...column.items];
        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, removed);

        setColumns({
            ...columns,
            [columnId]: {
                ...column,
                items: copiedItems
            }
        });
    };

    return (
        <div>
            <Grid container spacing={2} justifyContent={'center'}>
                <Grid item xs={12}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex justify-content-center align-items-center text-center gap-1'>
                            <Typography style={{ marginTop: '13px' }}>Galeri Üstte</Typography>
                            <IOSSwitch />
                            <Typography style={{ marginTop: '13px' }}>Fotoğraflar Üstte</Typography>
                        </div>
                        <div className='d-flex gap-1'>
                            <Button style={{ borderRadius: '5px', padding: '10px', paddingLeft: '15px', paddingRight: '15px' }} variant='contained' color='primary'>Yeni Fotoğraf Ekle</Button>
                            <Button style={{ borderRadius: '5px', padding: '10px', paddingLeft: '15px', paddingRight: '15px' }} variant='contained' color='primary'>Yeni Galeri Ekle</Button>
                        </div>
                    </div>
                </Grid>
                {Object.entries(columns).map(([columnId, column], index) => (
                    <Grid item xs={12} md={4} key={columnId}>
                        <Card variant="elevation" elevation={2} sx={{ borderRadius: '15px', padding: '15px' }}>
                            <Typography variant="h6" gutterBottom>{column.title}</Typography>
                            <DragDropContext onDragEnd={(result) => onDragEndColumn(result, columnId)}>
                                <Droppable droppableId={columnId} type="TASK">
                                    {(provided) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {column.items.map((item, index) => (
                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                    {(provided) => (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Card variant="elevation" elevation={2} sx={{ borderRadius: '15px', padding: '8px' }}>
                                                                <img src={image} style={{ borderRadius: '10px', height: '150px', width: '100%' }} alt="example" />
                                                                <Typography fontWeight={800} style={{ marginTop: '10px', textAlign: 'center' }}>{item.content}</Typography>
                                                                <div className="d-flex justify-content-around align-items-center gap-2" style={{ padding: '0px 15px 15px 15px' }}>
                                                                    <Trash />
                                                                    <Edit />
                                                                </div>
                                                            </Card>
                                                        </Grid>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </Grid>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default Index;
