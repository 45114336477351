import { useState } from 'react'
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import Themes from './Themes'
import SelfThemes from './SelfThemes'
import { Button, Card, Typography } from '@mui/material'
import theme from 'src/theme'
import { Link, Mail, Map, User } from 'react-feather'
import { BiWorld } from 'react-icons/bi'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import image from '../../../assets/images/main/wp-background.png'
import phone from '../../../assets/images/main/phone.png'
import '../main/index.scss'
import { FaPencilRuler } from 'react-icons/fa'

const index = () => {
    const [value, setValue] = useState(1)
    const [valueLinks, setValueLinks] = useState(1)
    const [currentShape, setCurrentShape] = useState(0)
    const [imageShapes, setImageShapes]: any = useState([
        {
            id: 0,
            shape: 'oval',
            style: { height: '100px', width: '100px', borderRadius: '100%', textAlign: 'center', cursor: 'pointer' }
        },
        {
            id: 1,
            shape: 'square',
            style: { height: '100px', width: '100px', textAlign: 'center', cursor: 'pointer' }
        },
        {
            id: 2,
            shape: 'radius',
            style: { height: '100px', width: '100px', borderRadius: '20px', textAlign: 'center', cursor: 'pointer' }
        },
    ])

    const profileValidationSchema: any = Yup.object({
        image_style: Yup.string().required('Bu alan zorunludur'),
        profile_name: Yup.string().required('Bu alan zorunludur'),
        description: Yup.string().required('Bu alan zorunludur'),
        position: Yup.string().required('Bu alan zorunludur'),
    })

    const profileFormik: any = useFormik({
        initialValues: {
            image_style: '',
            profile_name: 'Test Deneme',
            description: 'Test Deneme İçerik Açıklama',
            position: ''
        },
        validationSchema: profileValidationSchema,
        onSubmit: (values) => {
            console.log(values)
        }
    })
    const IconValidationSchema = Yup.object({
        shape: Yup.string().required('Bu alan zorunludur*'),
        card_position: Yup.string().required('Bu alan zorunludur*'),
        btn_color: Yup.string().required('Bu alan zorunludur*'),
        position: Yup.string().required('Bu alan zorunludur*'),
    })

    const IconFormik: any = useFormik({
        initialValues: {
            shape: 1,
            card_position: 1,
            btn_color: 1,
            position: 1,
            btn_text_color: 1,
            text_color: 1,
            bg_color: 1
        },
        validationSchema: IconValidationSchema,
        onSubmit: (values) => {
            console.log(values)
        },
    })
    return (
        <Row className='d-flex justify-content-between align-items-start'>
            <Col xs={12} sm={12} md={12} lg={9} className='mt-2'>
                <Nav tabs style={{ backgroundColor: '#0B3454', marginBottom: "0px", paddingTop: '15px', borderTopLeftRadius: '44px', borderTopRightRadius: '44px' }} >
                    <NavItem>
                        <NavLink
                            className=""
                            onClick={() => setValue(1)}
                        >
                            <div style={{ padding: "10px", color: value === 1 ? theme.palette.primary.main : 'white' }}>
                                <Typography fontWeight={700}>
                                    <FaPencilRuler size={25} />
                                    Tasarım
                                </Typography>
                            </div>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={value}>
                    <TabPane tabId={1}>
                        <Card variant='elevation' elevation={0} className='p-2' style={{ backgroundColor: '#FFF1E5', padding: '10px', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }} >
                            <div style={{ border: '2px solid white', borderRadius: '10px', padding: '20px' }}>
                                <Nav tabs>
                                    <div style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'row', padding: '4px', borderRadius: '50px', width: '100%' }}>
                                        <NavItem style={{ width: '100%' }}>
                                            <NavLink
                                                className=""
                                                style={{ padding: '0px', margin: '0px', width: '100%' }}
                                                onClick={() => setValueLinks(1)}
                                            >
                                                <Button onClick={() => setValueLinks(1)} style={{ width: '100%', borderRadius: '50px', color: valueLinks === 1 ? 'white' : theme.palette.primary.main, backgroundColor: valueLinks === 1 ? theme.palette.primary.main : 'white' }} variant={valueLinks === 1 ? 'contained' : 'text'} color='secondary'>
                                                    Profil
                                                </Button>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem style={{ width: '100%' }}>
                                            <NavLink
                                                className=""
                                                style={{ padding: '0px', margin: '0px', width: '100%' }}
                                                onClick={() => setValueLinks(2)}
                                            >
                                                <Button onClick={() => setValueLinks(2)} style={{ width: '100%', borderRadius: '50px', color: valueLinks === 2 ? 'white' : theme.palette.primary.main, backgroundColor: valueLinks === 2 ? theme.palette.primary.main : 'white' }} variant={valueLinks === 2 ? 'contained' : 'text'} color='secondary'>
                                                    Sayfa
                                                </Button>
                                            </NavLink>
                                        </NavItem>
                                    </div>
                                </Nav>
                                <TabContent activeTab={valueLinks}>
                                    <TabPane tabId={1}>
                                        <Themes />
                                    </TabPane>
                                    <TabPane tabId={2}>
                                        <SelfThemes IconFormik={IconFormik} />
                                    </TabPane>
                                </TabContent>
                            </div>
                        </Card>
                    </TabPane>
                    <TabPane tabId={2}>
                        Medya
                    </TabPane>
                </TabContent>
            </Col>
            <Col xs={12} sm={12} md={12} lg={3} className='mt-5'>
                <div className="phone-container">
                    <div>
                        <img width={'100%'} src={phone} />
                    </div>
                    <div className="phone">
                        <Row className='d-flex justify-content-center text-center'>
                            <Col xs={12}>
                                <img src={image} alt='profile_image' style={imageShapes[currentShape].style} />
                            </Col>
                            <Col xs={12} className='mt-1'>
                                <h4 style={{ lineBreak: 'anywhere', color: 'black' }}>
                                    @test
                                </h4>
                            </Col>
                            <Col xs={12}>
                                <span style={{ lineBreak: 'anywhere', color: '#A7A8A9' }}>
                                    test
                                </span>
                            </Col>
                            <Col xs={9}>
                                <div className='d-flex gap-1 justify-content-center align-items-center'>
                                    <div className='mt-1'>
                                        <Mail color={theme.palette.primary.main} size={25} />
                                    </div>
                                    <div className='mt-1'>
                                        <BiWorld color={theme.palette.primary.main} size={25} />
                                    </div>
                                    <div className='mt-1'>
                                        <User color={theme.palette.primary.main} size={25} />
                                    </div>
                                    <div className='mt-1'>
                                        <Map color={theme.palette.primary.main} size={25} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default index