// ** React Imports
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

// ** Icons Imports
import { Disc, X, Circle } from 'react-feather'

// ** Config
import themeConfig from '@configs/themeConfig'

// ** Utils
import { getUserData, getHomeRouteForLoggedInUser } from '@utils'
import theme from 'src/theme'

const VerticalMenuHeader = (props) => {
  // ** Props
  const {
    menuCollapsed,
    setMenuCollapsed,
    setMenuVisibility,
    setGroupOpen,
    menuHover,
  } = props

  // ** Vars
  const user = getUserData()

  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([])
  }, [menuHover, menuCollapsed])

  // ** Menu toggler component
  const Toggler = () => {
    if (!menuCollapsed) {
      return (
        <Disc
          size={20}
          color={theme.palette.primary.main}
          data-tour="toggle-icon"
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => {
            setMenuCollapsed(true)
          }}
        />
      )
    } else {
      return (
        <Circle
          color={theme.palette.primary.main}
          size={20}
          data-tour="toggle-icon"
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => {
            setMenuCollapsed(false)
          }}
        />
      )
    }
  }

  return (
    <div className="navbar-header" style={{ marginBottom: '35px' }}>
      <ul className="nav navbar-nav flex-row">
        <li className="nav-item me-auto">
          <NavLink to={'/'} className="navbar-brand">
            <span className="brand-logo">
              <img src='/image/logo.png' alt="logo" style={{ maxWidth: '189px !important' }} />
            </span>
          </NavLink>
        </li>
        <li className="nav-item nav-toggle" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '25px' }}>
          <div className="nav-link modern-nav-toggle cursor-pointer">
            <Toggler />
            <X
              onClick={() => setMenuVisibility(true)}
              className="toggle-icon icon-x d-block d-xl-none"
              size={20}
            />
          </div>
        </li>
      </ul>
    </div>
  )
}

export default VerticalMenuHeader
