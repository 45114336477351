import React, { useEffect } from 'react'
import {
  Row,
  Col,
  CardTitle,
  CardText,
  Label,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
const Verification = ({
  codeModal,
  setCodeModal,
  phoneNum,
  deleteDispatch,
  isSended,
  isDeleted,
  sendSmsDispatch,
}: any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  }: any = useFormik({
    initialValues: {
      code: '',
    },
    onSubmit: (values) => {
      deleteDispatch(values)
    },
  })

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (isRegisterCodeValid === 'Not Valid') {
  //       toast.error(t('Girdiğiniz kod geçersiz!'))
  //     }
  //   }, 250)
  // }, [isRegisterCodeValid])

  return (
    <Modal isOpen={codeModal} centered size="lg">
      <ModalHeader
        className="bg-transparent text-center"
        toggle={() => {
          setCodeModal(!codeModal)
        }}
      ></ModalHeader>
      <ModalBody className="mb-2">
        {isSended ? (
          <Row>
            <Col sm={12} className="text-center">
              <h1>Gelen Kodu Giriniz:</h1>
            </Col>
            <Col sm={12} className="text-center">
              <div>
                <span>Telefonunuza Onay Kodu Gönderildi</span>
              </div>
            </Col>
            <Col sm={12} className="">
              <Row className="d-flex justify-content-center">
                <Col sm="4" className="my-3">
                  <Input
                    type="text"
                    name="code"
                    className="text-center py-1"
                    style={{ fontSize: '54px' }}
                    maxLength={4}
                    value={values.code}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm="12" className="">
              <Row className="d-flex justify-content-center">
                <Col sm="4" className="">
                  <Button
                    color="primary"
                    className="w-100"
                    onClick={handleSubmit}
                  >
                    Gönder
                  </Button>
                </Col>
                <Col sm="4" className="">
                  <Button
                    color="primary"
                    className="w-100"
                    onClick={() => sendSmsDispatch()}
                  >
                    Tekrar Gönder
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col sm={12} className="text-center">
              <h1>Üyeyi Sil</h1>
            </Col>
            <Col sm={12} className="text-center mt-2">
              <div>
                <span>Telefonunuza Onay Kodu Gönderilsin mi?</span>
              </div>
            </Col>
            <Col sm="12" className="mt-2">
              <Row className="d-flex justify-content-center">
                <Col sm="4" className="">
                  <Button
                    color="primary"
                    className="w-100"
                    onClick={() => sendSmsDispatch()}
                  >
                    Sms Gönder
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </ModalBody>
    </Modal>
  )
}

export default Verification
