import { Button, Card, Chip, FormControlLabel, Input, InputLabel, OutlinedInput, Radio, Switch, SwitchProps, TextField, styled } from '@mui/material'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { ArrowRight, Edit2, Image, Instagram, Link, Link2, Linkedin, MoreVertical, Plus, PlusCircle, Search, Star, Trash, Trash2, Twitter, Youtube } from 'react-feather'
import { BiRightArrow } from 'react-icons/bi'
import { BsTiktok } from 'react-icons/bs'
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import theme from 'src/theme'
import * as Yup from 'yup'

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const index = () => {
    const [urlModal, setUrlModal] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [iconPositionModal, setIconPositionModal] = useState(false)

    const UrlValidationSchema = Yup.object({
        url: Yup.string().required('Bu alan zorunludur*'),
        title: Yup.string().required('Bu alan zorunludur*'),
        icon: Yup.string().required('Bu alan zorunludur*'),
    })

    const IconValidationSchema = Yup.object({
        shape: Yup.string().required('Bu alan zorunludur*'),
        card_position: Yup.string().required('Bu alan zorunludur*'),
        btn_color: Yup.string().required('Bu alan zorunludur*'),
        position: Yup.string().required('Bu alan zorunludur*'),
    })

    const FormValidationSchema = Yup.object({
        textInputs: Yup.array().min(1).required('Bu alan zorunludur*'),
    })

    const UrlFormik: any = useFormik({
        initialValues: {
            url: '',
            title: '',
            icon: '',
        },
        validationSchema: UrlValidationSchema,
        onSubmit: (values) => {
            console.log(values)
        },
    })

    const FormFormik: any = useFormik({
        initialValues: {
            textInputs: [],
        },
        validationSchema: FormValidationSchema,
        onSubmit: (values) => {
            console.log(values)
        },
    })

    const IconFormik: any = useFormik({
        initialValues: {
            shape: 1,
            card_position: 1,
            btn_color: 1,
            position: 1,
        },
        validationSchema: IconValidationSchema,
        onSubmit: (values) => {
            console.log(values)
        },
    })

    return (
        <Row className='d-flex justify-content-center align-items-start'>
            <Col xs={10} className='mt-2 mb-1'>
                <Row className='d-flex justify-content-around align-items-center'>
                    <Col sm={4} className=''>
                        <Button fullWidth style={{ cursor: 'pointer', backgroundColor: 'white', borderRadius: '7px', padding: '10px', textAlign: 'center', color: 'black' }}>
                            <PlusCircle />
                            <span style={{ marginLeft: '7px', marginTop: '2px' }}>Başlık Ekle</span>
                        </Button>
                    </Col>
                    <Col sm={4} style={{ cursor: 'pointer' }} className=''>
                        <Button fullWidth style={{ cursor: 'pointer', backgroundColor: 'white', borderRadius: '7px', padding: '10px', textAlign: 'center', color: 'black' }} onClick={() => setFormModal(!formModal)}>
                            <PlusCircle />
                            <span style={{ marginLeft: '7px', marginTop: '2px' }}>Form Ekle</span>
                        </Button>
                    </Col>
                    <Col sm={4} style={{ cursor: 'pointer' }} className=''>
                        <Button fullWidth style={{ cursor: 'pointer', backgroundColor: 'white', borderRadius: '7px', padding: '10px', textAlign: 'center', color: 'black' }} onClick={() => setUrlModal(!urlModal)}>
                            <PlusCircle />
                            <span style={{ marginLeft: '7px', marginTop: '2px' }}>Link Ekle</span>
                        </Button>
                    </Col>
                </Row>
            </Col>
            <Col xs={11}>
                <Row className='d-flex flex-row justify-content-around align-items-center'>
                    <Col xs={11}>
                        <Card style={{ borderRadius: '10px' }} variant='outlined' className='mt-2'>
                            <Row className='p-2 d-flex justify-content-between align-items-center'>
                                <Col xs={10} className='d-flex justify-content-start align-items-center'>
                                    <Row style={{ marginLeft: '10px' }} className='d-flex gap-1'>
                                        <Col xs={12}>
                                            <span>
                                                İnstagram
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={2}>
                                    <Edit2 cursor={'pointer'} color={theme.palette.primary.main} />
                                    <Trash cursor={'pointer'} style={{ marginLeft: '10px' }} color={theme.palette.primary.main} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={1}>
                        <IOSSwitch />
                    </Col>
                </Row>
            </Col>
            <Col xs={11}>
                <Row className='d-flex flex-row justify-content-around align-items-center'>
                    <Col className='d-flex justify-content-center' xs={1}>
                        <Star size={28} color={theme.palette.primary.main} />
                    </Col>
                    <Col xs={10}>
                        <Card style={{ borderRadius: '10px' }} variant='outlined' className='mt-2'>
                            <Row className='p-2 d-flex justify-content-between align-items-center'>
                                <Col xs={11} className='d-flex justify-content-start align-items-center'>
                                    <Row style={{ marginLeft: '10px' }} className='d-flex gap-1'>
                                        <Col xs={12}>
                                            <span>
                                                İnstagram
                                            </span>
                                            <Edit2 size={20} cursor={'pointer'} style={{ marginLeft: '10px' }} color={theme.palette.primary.main} />
                                        </Col>
                                        <Col xs={12}>
                                            <span>
                                                https://instagram.com
                                            </span>
                                            <Edit2 size={20} cursor={'pointer'} style={{ marginLeft: '10px' }} color={theme.palette.primary.main} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={1}>
                                    <Trash cursor={'pointer'} color={theme.palette.primary.main} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={1}>
                        <IOSSwitch />
                    </Col>
                </Row>
            </Col>
            <Col xs={11} className='mt-4'>
                <Row className='d-flex justify-content-end'>
                    <Col xs={4}>
                        <Button color='secondary' onClick={() => setIconPositionModal(true)} variant='contained' style={{ padding: '11px', paddingLeft: '15px', paddingRight: '15px', borderRadius: '5px' }} >
                            İkon Yerleşimini Değiştir
                            <ArrowRight style={{ marginLeft: '5px' }} size={22} />
                        </Button>
                    </Col>
                </Row>
            </Col>
            <Modal isOpen={urlModal} centered size="md">
                <ModalHeader
                    className="bg-transparent text-center"
                    toggle={() => {
                        setUrlModal(!urlModal)
                    }}
                ></ModalHeader>
                <ModalBody className="mb-2">
                    <Row>
                        <Col sm={12} className="text-center">
                            <h3>Link ekle</h3>
                        </Col>
                        <Col sm={12} className="mt-2">
                            <Row className="d-flex justify-content-center gap-1">
                                <Col xs={12} className="">
                                    <Label>
                                        URL
                                    </Label>
                                    <OutlinedInput
                                        startAdornment={<Link color={theme.palette.primary.main} />}
                                        style={{ width: '100%', border: '1px solid #DCE0E4', borderRadius: '5px', backgroundColor: 'white' }}
                                        type="url"
                                        name="url"
                                    />
                                </Col>
                                <Col xs={12}>
                                    <TextField
                                        placeholder='Başlık'
                                        style={{ width: '100%', border: '1px solid #DCE0E4', borderRadius: '5px', backgroundColor: 'white' }}
                                        type='text'
                                        name='title'
                                    />
                                </Col>
                                <Col xs={12} className='mt-1'>
                                    <OutlinedInput
                                        startAdornment={<Search color={theme.palette.primary.main} style={{ marginRight: '10px' }} />}
                                        placeholder='Uygulama Seç'
                                        style={{ width: '100%', border: '1px solid #DCE0E4', borderRadius: '5px', backgroundColor: 'white' }}
                                        type='text'
                                        name='title'
                                    />
                                    <Row className='mt-1'>
                                        <Col className='d-flex gap-1 align-items-center' style={{ color: '#A7A8A9' }}>
                                            <div style={{ border: '1px solid #A7A8A9', borderRadius: '100%', padding: '10px' }} >
                                                <Instagram />
                                            </div>
                                            <div style={{ border: '1px solid #A7A8A9', borderRadius: '100%', padding: '10px' }} >
                                                <BsTiktok size={25} />
                                            </div>
                                            <div style={{ border: '1px solid #A7A8A9', borderRadius: '100%', padding: '10px' }} >
                                                <Twitter />
                                            </div>
                                            <div style={{ border: '1px solid #A7A8A9', borderRadius: '100%', padding: '10px' }} >
                                                <Youtube />
                                            </div>
                                            <div style={{ border: '1px solid #A7A8A9', borderRadius: '100%', padding: '10px' }} >
                                                <Linkedin />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="12" className="mt-3">
                            <Row className="d-flex justify-content-end">
                                <Col sm="4" className="">

                                    <Button
                                        fullWidth
                                        style={{ borderRadius: '5px' }}
                                        variant='outlined'
                                        color="inherit"
                                        className="w-100"
                                        onClick={() => setUrlModal(!urlModal)}
                                    >
                                        İptal
                                    </Button>
                                </Col>
                                <Col sm="4" className="">
                                    <Button
                                        fullWidth
                                        style={{ borderRadius: '5px' }}
                                        color="primary"
                                        variant='contained'
                                        className="w-100"
                                    >
                                        Ekle
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal isOpen={formModal} centered size="md">
                <ModalHeader
                    className="bg-transparent text-center"
                    toggle={() => {
                        setFormModal(!formModal)
                    }}
                ></ModalHeader>
                <ModalBody className="mb-2">
                    <Row>
                        <Col sm={12} className="text-center">
                            <h3>Form ekle</h3>
                        </Col>
                        <Col sm={12} className="mt-2">
                            <Row className="d-flex justify-content-center gap-1 p-3">
                                <Col xs={12} className="">
                                    <Label className='mb-1' style={{ width: '100%' }}>
                                        Form Başlığı
                                        <Plus style={{ float: 'right' }} cursor={'pointer'} />
                                    </Label>
                                    <OutlinedInput
                                        placeholder='Text'
                                        label='Form Başlığı'
                                        fullWidth
                                        style={{ width: '100%', border: '1px solid #DCE0E4', borderRadius: '5px', backgroundColor: 'white' }}
                                        type='text'
                                        endAdornment={
                                            <Trash2 cursor={'pointer'} />
                                        }
                                        name='arry_text_1'
                                    />
                                </Col>
                                <Col xs={12}>
                                    <Row className='d-flex justify-content-around align-items-end'>
                                        <Col xs={12}>
                                            <OutlinedInput
                                                placeholder='Text'
                                                fullWidth
                                                style={{ width: '100%', border: '1px solid #DCE0E4', borderRadius: '5px', backgroundColor: 'white' }}
                                                type='text'
                                                endAdornment={
                                                    <Trash2 cursor={'pointer'} />
                                                }
                                                name='arry_text_1'
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12}>
                                    <Star style={{ marginRight: '7px' }} />
                                    <IOSSwitch />
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="12" className="mt-3">
                            <Row className="d-flex justify-content-end">
                                <Col sm="4" className="">

                                    <Button
                                        fullWidth
                                        style={{ borderRadius: '5px' }}
                                        variant='outlined'
                                        color="inherit"
                                        className="w-100"
                                        onClick={() => setFormModal(!formModal)}
                                    >
                                        İptal
                                    </Button>
                                </Col>
                                <Col sm="4" className="">
                                    <Button
                                        fullWidth
                                        style={{ borderRadius: '5px' }}
                                        color="primary"
                                        variant='contained'
                                        className="w-100"
                                    >
                                        Ekle
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal isOpen={iconPositionModal} centered size="lg">
                <ModalHeader
                    className="bg-transparent text-center"
                    toggle={() => {
                        setIconPositionModal(!iconPositionModal)
                    }}
                ></ModalHeader>
                <ModalBody className="mb-2">
                    <Row>
                        <Col sm={12} className="text-center">
                            <h3>İkon Tasarımı</h3>
                        </Col>
                        <Col sm={12} className="mt-2">
                            <Row className="d-flex justify-content-center gap-1">
                                <Col xs={11}>
                                    <h6 style={{ color: '#4C4C9A', fontWeight: '600' }}>Resim Stili</h6>
                                    <Row className='d-flex justify-content-start mt-1 gap-2'>
                                        <Card variant='elevation' elevation={0} className='cursor-pointer' style={{ border: IconFormik.values.shape === 1 ? `2px solid ${theme.palette.primary.main}` : 'none', height: '100px', width: '100px', borderRadius: '100%', textAlign: 'center', backgroundColor: '#F4F4F4', cursor: 'pointer' }} onClick={() => IconFormik.setFieldValue('shape', 1)} title='Oval' />
                                        <Card variant='elevation' elevation={0} className='cursor-pointer' style={{ border: IconFormik.values.shape === 2 ? `2px solid ${theme.palette.primary.main}` : 'none', height: '100px', width: '100px', textAlign: 'center', backgroundColor: '#F4F4F4', cursor: 'pointer' }} onClick={() => IconFormik.setFieldValue('shape', 2)} title='Square' />
                                        <Card variant='elevation' elevation={0} className='cursor-pointer' style={{ border: IconFormik.values.shape === 3 ? `2px solid ${theme.palette.primary.main}` : 'none', height: '100px', width: '100px', borderRadius: '20px', textAlign: 'center', backgroundColor: '#F4F4F4', cursor: 'pointer' }} onClick={() => IconFormik.setFieldValue('shape', 3)} title='Radius' />
                                    </Row>
                                </Col>
                                <Col xs={11} className='mt-2'>
                                    <h6 style={{ color: '#4C4C9A', fontWeight: '600' }}>İkon Yerleşimi</h6>
                                    <Row className='mt-1 gap-2'>
                                        <Col onClick={() => IconFormik.setFieldValue('card_position', 1)} style={{ cursor: 'pointer', border: IconFormik.values.card_position === 1 ? `2px solid ${theme.palette.primary.main}` : '2px solid transparent', backgroundColor: '#F4F4F4', padding: '10px', borderRadius: '10px' }}>
                                            <Row className='d-flex justify-content-start'>
                                                <Col xs={4}>
                                                    <Card variant='elevation' elevation={0} className='cursor-pointer' style={{ height: '100px', width: '60px', textAlign: 'center' }} title='Square' />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col onClick={() => IconFormik.setFieldValue('card_position', 2)} style={{ cursor: 'pointer', border: IconFormik.values.card_position === 2 ? `2px solid ${theme.palette.primary.main}` : '2px solid transparent', backgroundColor: '#F4F4F4', padding: '10px', borderRadius: '10px' }}>
                                            <Row className='d-flex justify-content-start'>
                                                <Col xs={4}>
                                                    <Card variant='elevation' elevation={0} className='cursor-pointer' style={{ height: '100px', width: '60px', textAlign: 'center' }} title='Square' />
                                                </Col>
                                                <Col xs={4}>
                                                    <Card variant='elevation' elevation={0} className='cursor-pointer' style={{ height: '100px', width: '60px', textAlign: 'center' }} title='Square' />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col onClick={() => IconFormik.setFieldValue('card_position', 3)} style={{ cursor: 'pointer', border: IconFormik.values.card_position === 3 ? `2px solid ${theme.palette.primary.main}` : '2px solid transparent', backgroundColor: '#F4F4F4', padding: '10px', borderRadius: '10px' }}>
                                            <Row className='d-flex justify-content-around'>
                                                <Card variant='elevation' elevation={0} className='cursor-pointer' style={{ height: '100px', width: '60px', textAlign: 'center' }} title='Square' />
                                                <Card variant='elevation' elevation={0} className='cursor-pointer' style={{ height: '100px', width: '60px', textAlign: 'center' }} title='Square' />
                                                <Card variant='elevation' elevation={0} className='cursor-pointer' style={{ height: '100px', width: '60px', textAlign: 'center' }} title='Square' />
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={11} className='mt-2'>
                                    <h6 style={{ color: '#4C4C9A', fontWeight: '600' }}>Buton Rengi</h6>
                                    <div className='mt-1 d-flex justify-content-start gap-1'>
                                        <div
                                            style={{ height: '25px', width: '25px', backgroundColor: '#00AB55', borderRadius: '100%', cursor: 'pointer' }}
                                        />
                                        <div
                                            style={{ height: '25px', width: '25px', backgroundColor: '#00B8D9', borderRadius: '100%', cursor: 'pointer' }}
                                        />
                                        <div
                                            style={{ height: '25px', width: '25px', backgroundColor: '#003768', borderRadius: '100%', cursor: 'pointer' }}
                                        />
                                        <div
                                            style={{ height: '25px', width: '25px', backgroundColor: '#FFAB00', borderRadius: '100%', cursor: 'pointer' }}
                                        />
                                        <div
                                            style={{ height: '25px', width: '25px', backgroundColor: '#FFAC82', borderRadius: '100%', cursor: 'pointer' }}
                                        />
                                        <div
                                            style={{ height: '25px', width: '25px', backgroundColor: '#B71D18', borderRadius: '100%', cursor: 'pointer' }}
                                        />
                                    </div>
                                </Col>
                                <Col xs={11} className='mt-2'>
                                    <h6 style={{ color: '#4C4C9A', fontWeight: '600' }}>Buton Rengi</h6>
                                    <div className='mt-1 d-flex justify-content-start gap-1'>
                                        <FormControlLabel value="ontop" control={<Radio />} label="Üstte" />
                                        <FormControlLabel value="under" control={<Radio />} label="Altta" />
                                        <FormControlLabel value="right" control={<Radio />} label="Sağda" />
                                        <FormControlLabel value="left" control={<Radio />} label="Solda" />
                                        <FormControlLabel value="upside" control={<Radio />} label="Üzerinde" />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="12" className="mt-3">
                            <Row className="d-flex justify-content-end">
                                <Col sm="2" className="">

                                    <Button
                                        fullWidth
                                        style={{ borderRadius: '5px' }}
                                        variant='outlined'
                                        color="inherit"
                                        className="w-100"
                                        onClick={() => setIconPositionModal(!iconPositionModal)}
                                    >
                                        İptal
                                    </Button>
                                </Col>
                                <Col sm="2" className="">
                                    <Button
                                        fullWidth
                                        color='primary'
                                        style={{ borderRadius: '5px', color: 'white' }}
                                        variant='contained'
                                        className="w-100"
                                    >
                                        Ekle
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Row>
    )
}

export default index