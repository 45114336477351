// ** React Imports
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'

// ** Third Party Components
import {
  User,
  Mail,
  CheckSquare,
  MessageSquare,
  Settings,
  CreditCard,
  HelpCircle,
  Power,
  Clipboard,
  Users,
} from 'react-feather'

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap'

// ** Default Avatar Image
import defaultAvatar from 'src/assets/images/portrait/small/avatar-s-11.jpg'
import { useTranslation } from 'react-i18next'
import { application } from '../../../../redux/store'
import { UserTypes } from './store/types'
import jwt_decode from 'jwt-decode'
import { useQueryClient } from '@tanstack/react-query'
//import 'src/helpers/hawk'
import {
  userDeviceGetHook,
  userPhotoHook,
} from 'src/views/pages/profile/storeHook/hook'
import { logoutClear } from 'src/utility/Utils'
const UserDropdown = () => {
  const { t } = useTranslation()
  // ** Store Vars
  //const userPhoto = userPhotoHook()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const user =
    queryClient.getQueryData(['user']) ||
    queryClient.setQueryData(['user'], JSON.parse(localStorage.getItem('user')))
  useEffect(() => {
    dispatch({ type: UserTypes.GET_USER })
  }, [])
  // useEffect(() => {
  //   const userData = jwt_decode(localStorage.getItem('user'))
  //   setUser({
  //     id: userData.Personel?.id,
  //     name: userData.Personel?.name,
  //     surname: userData.Personel?.surname,
  //   })
  // }, [])
  // const user = useSelector((state) => state.UserSlice.user)

  //** Vars
  // const userAvatar = (userData && userData.avatar) || defaultAvatar;
  const handleLogout = () => {
    logoutClear()
    window.location.href = '/login'
  }

  return (
    <UncontrolledDropdown
      tag="li"
      style={{ zIndex: 25 }}
      className="dropdown-user nav-item"
    >
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex d-none text-center">
          <span
            style={{
              marginTop: '5px',
              float: 'left',
              textAlign: 'left',
              width: '100%',
            }}
          >
            {/* {userInf?.data?.name} {userInf?.data?.surname} */}
          </span>
          {/* <span className="user-name fw-bold">
            { user?.assoc_name || "Kullanıcı"}
          </span>
          <span className="user-status">
            {"Admin"}
          </span> */}
          <span
            className="user-status fw-bolder"
            style={{
              marginTop: '4px',
              float: 'left',
              textAlign: 'left',
              width: '100%',
            }}
          >
            {/* {t('Kalan Kredi')}:{' '} */}
            {/* {userInf?.data?.message_usage.toLocaleString('en-US') ?? 0} */}
          </span>
          <span
            className="user-status fw-bolder text-left"
            style={{
              marginTop: '2px',
              float: 'left',
              textAlign: 'left',
              width: '100%',
            }}
          >
            {/* {t('Üye no')}: {userInf?.data?.ID} */}
          </span>
          {/*<span className="user-status fw-bolder" style={{ marginTop: '4px' }}>
            {user?.id > 0 ? `${t('Üye no')}: ${user.id}` : null}
        </span>*/}
        </div>
        <Avatar
          //img={'https://www.svgrepo.com/show/70698/avatar.svg'}
          // img={`/image/avatars/avatar-${userInf?.data?.image === '' ? '1' : userInf?.data?.image
          //   }.png`}
          img=''
          imgHeight="40"
          imgWidth="40"
        />
      </DropdownToggle>
      <DropdownMenu end style={{ zIndex: 25 }}>
        <DropdownItem tag={Link} to="/profile">
          <User size={14} className="me-75" />
          <span className="align-middle">{t('Profil')}</span>
        </DropdownItem>
        <DropdownItem style={{ width: '100%' }} onClick={() => handleLogout()}>
          <Power size={14} className="me-75" />
          <span className="align-middle">{t('Çıkış')}</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
