import axios from 'axios'
import { toast } from 'react-hot-toast'

export const getWhatsappAccount = async (page: number) => {
  try {
    const res = await axios.get(`/whatsapp/devices?page=${page ?? 1}`)
    return res.data
  } catch (error) {
    return error
  }
}

export const whatsappLogin = async () => {
  try {
    const res = await axios.post('/whatsapp/login')
    return res.data
  } catch (error) {
    return error
  }
}
export const whatsappLogout = async (data: {
  identifier: string
  registration_id: number
}) => {
  try {
    const res = await axios.post('/whatsapp/logout', data)
    return res.data
  } catch (error) {
    return error
  }
}
export const whatsappLoginControl = async (deviceId?: number) => {
  try {
    const res = await axios.get(`/whatsapp/login/control?device_id=${deviceId}`)
    return res.data
  } catch (error) {
    return error
  }
}
export const whatsappUnreadMessage = async (identifier?: any) => {
  try {
    const res = await axios.get(`/whatsapp/unread?identifier=${identifier}`)
    return res.data
  } catch (error) {
    return error
  }
}

export const whatsappUnreadHeaderMessage = async (
  page?: number,
  identifier?: any
) => {
  try {
    if (identifier) {
      const res = await axios.get(
        `/whatsapp/unread?page=${page}&identifier=${identifier}`
      )
      return res.data
    } else {
      return null
    }
  } catch (error) {
    return error
  }
}

export const wpdeviceControl = async (registrationId: number) => {
  try {
    const res = await axios.get(
      `/whatsapp/ws/logincontrol?registrationId=${registrationId}`
    )
    return res.data
  } catch (error) {
    return error
  }
}

export const deleteNotifications = async (data: any) => {
  try {
    const res = await axios.post('/whatsapp/readchat', data)
    return res.data
  } catch (error) {
    return error
  }
}
