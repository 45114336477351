import { Button, Card, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import { BarChart2, Edit, Plus, PlusCircle, Settings, Star, Trash, Trash2, UploadCloud } from 'react-feather'
import { Col, Label, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import { IOSSwitch } from 'src/views/components/IOSSwitch'
import image from '../../../../assets/images/main/wp-background.png'
import theme from 'src/theme'

const index = () => {
  const inputRef: any = useRef()
  const [videoModal, setVideoModal] = useState(false)
  const [valueMenu, setValueMenu] = useState(1)
  const [age, setAge] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };
  return (
    <Row className='h-100'>
      <Col xs={12} className='mb-1'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex justify-content-center align-items-center text-center gap-1'>
            <Button startIcon={<PlusCircle color='#292D32' size={20} />} onClick={() => setVideoModal(true)} style={{ backgroundColor: 'white', borderRadius: '5px', padding: '10px', paddingLeft: '15px', paddingRight: '15px' }} variant='contained'>
              Video Ekle
            </Button>
          </div>
          <div className='d-flex gap-1'>
            <Button startIcon={<Settings color='#292D32' size={20} />} style={{ borderRadius: '5px', padding: '10px', paddingLeft: '15px', paddingRight: '15px' }} variant='contained' color='primary'>
              Ayarlar
            </Button>
          </div>
        </div>
      </Col>
      <hr />
      <Col xs={12}>
        <Row className='d-flex justify-content-start align-items-start'>
          <Col xs={12} sm={6} md={4}>
            <Card variant='elevation' elevation={2} sx={{ borderRadius: '15px', padding: '8px' }}>
              <Row className='d-flex justify-content-around align-items-center'>
                <Col xs={12}>
                  <img src={image} style={{ borderRadius: '10px', height: '150px', width: '100%' }} />
                </Col>
                <Col xs={12} style={{ marginTop: '10px', textAlign: 'center' }}>
                  <Typography fontWeight={800}>
                    Video 1
                  </Typography>
                </Col>
                <Col xs={12} style={{ padding: '0px 15px 15px 15px' }}>
                  <div className='d-flex justify-content-center align-items-center gap-2'>
                    <div >
                      <Trash />
                    </div>
                    <div>
                      <BarChart2 />
                    </div>
                    <div>
                      <Edit />
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
      <Modal isOpen={videoModal} centered size="md">
        <ModalHeader
          className="bg-transparent text-center"
          toggle={() => {
            setVideoModal(!videoModal)
          }}
        ></ModalHeader>
        <ModalBody className="mb-2">
          <Row>
            <Col sm={12} className="text-left" style={{ marginTop: '-20px' }}>
              <h3>Video Ekle</h3>
            </Col>
            <Col sm={12} className="mt-2">
              <OutlinedInput
                placeholder='Video Başlığı'
                label='Video Başlığı'
                fullWidth
                style={{ width: '100%', border: '1px solid #DCE0E4', borderRadius: '5px', backgroundColor: 'white' }}
                type='text'
                name='arry_text_1'
              />
            </Col>
            <Col sm={12} className="mt-1">
              <OutlinedInput
                placeholder='URL'
                label='URL'
                fullWidth
                style={{ width: '100%', border: '1px solid #DCE0E4', borderRadius: '5px', backgroundColor: 'white' }}
                type='text'
                name='arry_text_1'
              />
            </Col>
            <Col sm="12" className="mt-3">
              <Row className="d-flex justify-content-end">
                <Col sm="4" className="">

                  <Button
                    fullWidth
                    style={{ borderRadius: '5px' }}
                    variant='outlined'
                    color="inherit"
                    className="w-100"
                    onClick={() => setVideoModal(!videoModal)}
                  >
                    İptal
                  </Button>
                </Col>
                <Col sm="4" className="">
                  <Button
                    fullWidth
                    style={{ borderRadius: '5px' }}
                    color="primary"
                    variant='contained'
                    className="w-100"
                  >
                    Ekle
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Row>
  )
}

export default index