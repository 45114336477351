import { Link, Navigate, useNavigate } from 'react-router-dom'
import {
  Row,
  Col,
} from 'reactstrap'

import logo from 'src/assets/images/logo/vayonet-logo.png'


import { useSkin } from '../../../../utility/hooks/useSkin'
import { ChevronLeft } from 'react-feather'

import { useTranslation } from 'react-i18next'
import '@styles/react/pages/page-authentication.scss'
import { useState, useEffect } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { RqErrorMessage, minLength } from '../../../../helpers/RequiredMessage'
import formatNumber from '../../../../functions/Number/formatNumber'
import toast from 'react-hot-toast'
import PhoneInput from 'react-phone-input-2'
import { forgotPasswordConfirmHook, sendForgotPasswordHook } from './store/hook'
import LoadingSpinner from '../../whatsapp/campaign/components/loading'
import Router from 'src/router/Router'
import { getUserIpInfo } from '../../profile/store/service'
import { Button, IconButton, InputAdornment, OutlinedInput, Typography } from '@mui/material'
import { MdVisibilityOff } from 'react-icons/md'
import theme from 'src/theme'

const ForgotPassword = () => {
  const { t }: any = useTranslation()
  const { skin } = useSkin()
  const router = useNavigate()
  const source = require(`src/assets/images/pages/forgot_side.png`).default
  const [step, setStep] = useState(0)
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const { values, errors, handleChange, touched, resetForm, handleSubmit } = useFormik({
    initialValues: {
      password: "",
      repassword: ""
    },
    validationSchema: step === 1 && Yup.object({
      password: Yup.string().min(6, minLength).required(t('Bu alan zorunludur*')),
      repassword: Yup.string().min(6, minLength).required(t('Bu alan zorunludur*')),
    }),
    onSubmit: () => {

    },
  })

  return (
    <div className="auth-wrapper auth-cover">
      <Row className="auth-inner m-0">
        <Link
          className="brand-logo"
          to="/forgot-password"
        >
          <img src='/image/logo.png' alt="logo" height={60} />
        </Link>
        <Col
          className="d-flex align-items-center auth-bg px-2 p-lg-5"
          lg="5"
          sm="12"
          tag="form"
        >
          <Col className="px-xl-2 mx-auto text-center" sm="8" md="6" lg="12">
            <Typography variant='h3' fontWeight={900} color={'#1A1A1A'}>
              {t("Şifre Yenilet")}
            </Typography>
            <div className="auth-forgot-password-form mt-2">
              <div className="mb-1 mt-5">
                <OutlinedInput
                  fullWidth
                  placeholder='Şifre'
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <MdVisibilityOff /> : <MdVisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  sx={{ border: `1px solid #d8d6de` }}
                />

                {RqErrorMessage(errors.password, touched.password)}
              </div>
              <div className="mb-1 mt-2">
                <OutlinedInput
                  fullWidth
                  placeholder='Şifre Tekrar'
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <MdVisibilityOff /> : <MdVisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="RePassword"
                  name="repassword"
                  value={values.repassword}
                  onChange={handleChange}
                  sx={{ border: `1px solid #d8d6de` }}
                />

                {RqErrorMessage(errors.repassword, touched.repassword)}
              </div>
              <Button className='mt-5' color="primary" variant='contained' fullWidth style={{ borderRadius: '100px', padding: '12px', fontWeight: '700', color: 'white' }}>
                {t("Şifre Yenile")}
              </Button>
            </div>
            <p
              className="text-center mt-2"
              onClick={() => resetForm()}
            >
              <Link to="/login">
                <ChevronLeft className="rotate-rtl me-25" size={14} />
                <span className="align-middle">{t("Giriş Sayfasına Dön")}</span>
              </Link>
            </p>
          </Col>
        </Col>
        <Col className="d-none d-lg-flex align-items-center" style={{ backgroundColor: theme.palette.primary.main }} lg="7" sm="12">
          <div className="w-100 d-lg-flex align-items-center justify-content-center">
            <img className="img-fluid" src={source} alt="Login Cover" />
          </div>
        </Col>
      </Row>
    </div >
  )
}

export default ForgotPassword
