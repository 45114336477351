// ** React Imports
import { Fragment, useEffect, memo, useState } from 'react'

// ** Third Party Components
import classnames from 'classnames'

// ** Store & Actions
import { useSelector, useDispatch } from 'react-redux'
import {
  handleContentWidth,
  handleMenuCollapsed,
  handleMenuHidden,
} from '@store/layout'

// ** Styles
import 'animate.css/animate.css'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import { CheckCircle } from 'react-feather'
import { useTranslation } from 'react-i18next'

const LayoutWrapper = (props) => {
  const { t } = useTranslation()
  // ** Props
  const { children, routeMeta } = props

  // ** Store Vars
  const dispatch = useDispatch()
  const store = useSelector((state) => state)

  const navbarStore = store.navbar
  const layoutStored = store.layout.layout
  const contentWidth = store.layout.contentWidth
  const transition = store.layout.routerTransition
  //** Vars
  const appLayoutCondition =
    (layoutStored.layout === 'horizontal' && !routeMeta) ||
    (layoutStored.layout === 'horizontal' && routeMeta && !routeMeta.appLayout)
  const Tag = appLayoutCondition ? 'div' : Fragment

  // ** Clean Up Function
  const cleanUp = () => {
    if (routeMeta) {
      if (routeMeta.contentWidth) {
        dispatch(handleContentWidth('full'))
      }
      if (routeMeta.menuCollapsed) {
        dispatch(handleMenuCollapsed(!routeMeta.menuCollapsed))
      }
      if (routeMeta.menuHidden) {
        dispatch(handleMenuHidden(!routeMeta.menuHidden))
      }
    }
  }

  // ** ComponentDidMount
  useEffect(() => {
    if (routeMeta) {
      if (routeMeta.contentWidth) {
        dispatch(handleContentWidth(routeMeta.contentWidth))
      }
      if (routeMeta.menuCollapsed) {
        dispatch(handleMenuCollapsed(routeMeta.menuCollapsed))
      }
      if (routeMeta.menuHidden) {
        dispatch(handleMenuHidden(routeMeta.menuHidden))
      }
    }
    return () => cleanUp()
  }, [routeMeta])

  return (
    <div
      className={classnames('app-content content overflow-hidden', {
        [routeMeta ? routeMeta.className : '']:
          routeMeta && routeMeta.className,
        'show-overlay': navbarStore.query.length,
      })}
      style={{ backgroundColor: 'white', paddingTop: '25px' }}
    >
      <div className="content-overlay"></div>
      <div className="header-navbar-shadow" style={{ zIndex: -2 }} />
      {/* <Row
        className="d-flex"
        style={{ padding: '0px 14px', overflowY: 'scroll' }}
      >
        <Card
          style={{
            display:
              tutorial?.is_complete === undefined
                ? 'none'
                : tutorial?.is_complete === true
                ? 'none'
                : null,
            zIndex: 10,
            marginTop: '35px',
          }}
        >
          <CardBody>
            <Row className="d-flex">
              <Col
                style={{
                  borderRight: '1px solid',
                  minWidth: '200px',
                  borderColor: tutorial?.step !== 1 ? '#E6E6E5' : '#D81A63',
                }}
              >
                <Link
                  state={'tutorial'}
                  style={
                    tutorial?.step !== 1
                      ? { pointerEvents: 'none', color: '#dbdbd9' }
                      : null
                  }
                  to={'/whatsapp/account'}
                >
                  <Row
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '75px' }}
                  >
                    <Col
                      xs="4"
                      className="d-flex justify-content-end align-items-center"
                    >
                      {tutorial?.step > 1 ? (
                        <CheckCircle size={32} />
                      ) : (
                        <div
                          style={{
                            background:
                              tutorial?.step === 1
                                ? 'linear-gradient(90deg, rgba(40, 181, 220, 1) 0%,rgba(62, 250, 11, 1) 100%)'
                                : '#DBDBD9',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '100%',
                            height: '30px',
                            fontWeight: '800',
                            width: '30px',
                          }}
                        >
                          1
                        </div>
                      )}
                    </Col>
                    <Col
                      xs="8"
                      className="d-flex justify-content-start align-items-center"
                    >
                      <span
                        style={{
                          textDecoration:
                            tutorial?.step > 1 ? 'line-through' : null,
                        }}
                      >
                        {t('Hesap Ekle')}
                      </span>
                    </Col>
                  </Row>
                </Link>
              </Col>
              <Col
                style={{
                  borderRight: '1px solid',
                  minWidth: '200px',
                  borderColor: tutorial?.step !== 2 ? '#E6E6E5' : '#D81A63',
                }}
              >
                <Link
                  state={'tutorial'}
                  style={
                    tutorial?.step !== 2
                      ? { pointerEvents: 'none', color: '#dbdbd9' }
                      : null
                  }
                  to={'/whatsapp/group'}
                >
                  <Row
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '75px' }}
                  >
                    <Col
                      xs="5"
                      className="d-flex justify-content-end align-items-center"
                    >
                      {tutorial?.step > 2 ? (
                        <CheckCircle size={32} />
                      ) : (
                        <div
                          style={{
                            background:
                              tutorial?.step === 2
                                ? 'linear-gradient(90deg, rgba(40, 181, 220, 1) 0%,rgba(62, 250, 11, 1) 100%)'
                                : '#DBDBD9',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '100%',
                            height: '30px',
                            fontWeight: '800',
                            width: '30px',
                          }}
                        >
                          2
                        </div>
                      )}
                    </Col>
                    <Col
                      xs="7"
                      className="d-flex justify-content-start align-items-center"
                    >
                      <span
                        style={{
                          textDecoration:
                            tutorial?.step > 2 ? 'line-through' : null,
                        }}
                      >
                        {t('Grup Oluştur')}
                      </span>
                    </Col>
                  </Row>
                </Link>
              </Col>
              <Col
                style={{
                  borderRight: '1px solid',
                  minWidth: '200px',
                  borderColor: tutorial?.step !== 3 ? '#E6E6E5' : '#D81A63',
                }}
              >
                <Link
                  state={'tutorial-2'}
                  id="group-tutorial-2"
                  style={
                    tutorial?.step !== 3
                      ? { pointerEvents: 'none', color: '#dbdbd9' }
                      : null
                  }
                  to={'/whatsapp/group'}
                >
                  <Row
                    className="d-flex direction-row justify-content-center align-items-center"
                    style={{ height: '75px' }}
                  >
                    <Col
                      xs="4"
                      className="d-flex justify-content-end align-items-center"
                    >
                      {tutorial?.step > 3 ? (
                        <CheckCircle size={32} />
                      ) : (
                        <div
                          style={{
                            background:
                              tutorial?.step === 3
                                ? 'linear-gradient(90deg, rgba(40, 181, 220, 1) 0%,rgba(62, 250, 11, 1) 100%)'
                                : '#DBDBD9',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '100%',
                            height: '30px',
                            fontWeight: '800',
                            width: '30px',
                          }}
                        >
                          3
                        </div>
                      )}
                    </Col>
                    <Col
                      xs="8"
                      className="d-flex justify-content-start align-items-center"
                    >
                      <span
                        style={{
                          textDecoration:
                            tutorial?.step > 3 ? 'line-through' : null,
                        }}
                      >
                        {t('Gruba Kişi Ekle')}
                      </span>
                    </Col>
                  </Row>
                </Link>
              </Col>
              <Col
                style={{
                  borderRight: '1px solid',
                  minWidth: '200px',
                  borderColor: tutorial?.step !== 4 ? '#E6E6E5' : '#D81A63',
                }}
              >
                <Link
                  state={'tutorial'}
                  style={
                    tutorial?.step !== 4
                      ? { pointerEvents: 'none', color: '#dbdbd9' }
                      : null
                  }
                  to={'/whatsapp/campaign'}
                >
                  <Row
                    className="d-flex direction-row justify-content-center align-items-center"
                    style={{ height: '75px' }}
                  >
                    <Col
                      xs="5"
                      className="d-flex justify-content-end align-items-center"
                    >
                      {tutorial?.step > 4 ? (
                        <CheckCircle size={32} />
                      ) : (
                        <div
                          style={{
                            background:
                              tutorial?.step === 4
                                ? 'linear-gradient(90deg, rgba(40, 181, 220, 1) 0%,rgba(62, 250, 11, 1) 100%)'
                                : '#DBDBD9',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '100%',
                            height: '30px',
                            fontWeight: '800',
                            width: '30px',
                          }}
                        >
                          4
                        </div>
                      )}
                    </Col>
                    <Col
                      xs="7"
                      className="d-flex justify-content-start align-items-center"
                    >
                      <span
                        style={{
                          textDecoration:
                            tutorial?.step > 4 ? 'line-through' : null,
                        }}
                      >
                        {t('Kampanya Oluşturun')}
                      </span>
                    </Col>
                  </Row>
                </Link>
              </Col>
              <Col>
                <Row
                  className="d-flex direction-row justify-content-center align-items-center"
                  style={{ height: '75px' }}
                >
                  <Col
                    xs="12"
                    className="d-flex justify-content-center align-items-center"
                  >
                    <span style={{ color: '#D81A63' }}>
                      {t('Tüm Adımları Tamamla Hediye 200 Mesaj KAZAN!!')}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row> */}
      <div
        style={{ marginTop: '15px' }}
        className={classnames({
          'content-wrapper': routeMeta && !routeMeta.appLayout,
          // 'content-area-wrapper': routeMeta && routeMeta.appLayout,
          'p-0': contentWidth === 'boxed',
          [`animate__animated animate__${transition}`]:
            transition !== 'none' && transition.length,
        })}
      >
        <Tag {...(appLayoutCondition ? { className: 'content-body' } : {})}>
          {children}
        </Tag>
      </div>
    </div>
  )
}

export default memo(LayoutWrapper)
