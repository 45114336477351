import { Card } from '@mui/material'
import React from 'react'
import { Col, Row } from 'reactstrap'

const index = () => {

    return (
        <Row className='d-flex justify-content-start align-items-start m-2'>
            <Col className='p-2' xs={12} sm={6} md={6} lg={6} style={{ height: '400px' }}>
                <Card style={{ width: '100%', height: '100%' }}>
                </Card>
            </Col>
            <Col className='p-2' xs={12} sm={6} md={6} lg={6} style={{ height: '400px' }}>
                <Card style={{ width: '100%', height: '100%' }}>
                </Card>
            </Col>
            <Col className='p-2' xs={12} sm={6} md={6} lg={6} style={{ height: '400px' }}>
                <Card style={{ width: '100%', height: '100%' }}>
                </Card>
            </Col>
        </Row>
    )
}

export default index