// ** Dropdowns Imports
import IntlDropdown from './IntlDropdown'
import CartDropdown from './CartDropdown'
import UserDropdown from './UserDropdown'
import NavbarSearch from './NavbarSearch'
import NotificationDropdown from './NotificationDropdown'
// ** Third Party Components
import { Sun, Moon, User, Power, Trash } from 'react-feather'

// ** Reactstrap Imports
import {
  Badge,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap'
import { MdNotifications } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import {
  deleteNotificationsAllHook,
  whatsappMessageUnreadHeaderHook,
} from 'src/views/pages/whatsapp/account/store/hook'
import { useEffect, useRef, useState } from 'react'
import { BiUserCircle } from 'react-icons/bi'
import './index.css'
import Avatar from '@components/avatar'
import { useTranslation } from 'react-i18next'

const NavbarUser = (props) => {
  const [page, setPage] = useState(1)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const unreadMessages = whatsappMessageUnreadHeaderHook(page)
  const deleteAll = deleteNotificationsAllHook()
  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState([])
  const [checkIsData, setCheckIsData] = useState(false)
  const containerRef = useRef(null)
  // ** Props
  const { skin, setSkin, unread } = props
  // ** Function to toggle Theme (Light/Dark)
  useEffect(() => {
    fetchData()
  }, [page])
  useEffect(() => {
    if (unreadMessages.isSuccess) {
      if (unreadMessages?.data?.length > 0) {
        setData((prevData) => [...prevData, ...unreadMessages?.data])
      } else {
        setCheckIsData(true)
      }
      setIsLoading(false)
    }
  }, [unreadMessages.isSuccess])
  const fetchData = async () => {
    setIsLoading(true)
    unreadMessages.refetch()
  }
  const handleScroll = () => {
    if (!checkIsData && !isLoading) {
      const container = containerRef.current

      if (
        container.scrollTop + container.clientHeight ===
        container.scrollHeight
      ) {
        setPage((prevPage) => prevPage + 1)
      }
    }
  }
  useEffect(() => {
    const container = containerRef.current
    container.onscroll = handleScroll
    return () => {
      container.onscroll = null
    }
  }, [])

  const ThemeToggler = () => {
    if (skin === 'dark') {
      return <Sun className="ficon" onClick={() => setSkin('light')} />
    } else {
      return <Moon className="ficon" onClick={() => setSkin('dark')} />
    }
  }
  useEffect(() => {
    if (deleteAll.isSuccess) {
      unreadMessages.refetch()
    }
  }, [deleteAll.isSuccess])
  const loadMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <ul className="nav navbar-nav align-items-center ms-auto">
      <IntlDropdown />
      <NavItem>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle
            href="/"
            tag="a"
            className="nav-link dropdown-user-link"
            style={{ position: 'relative' }}
            onClick={(e) => e.preventDefault()}
          >
            <div
              style={{
                display: props.unread === 0 ? 'none' : 'initial',
                float: 'right',
                right: parseInt(props.unread) >= 100 ? -8 : -2,
                top: -10,
                position: 'absolute',
              }}
            >
              <Badge color="success" pill>
                {parseInt(props.unread) >= 100 ? '+99' : props.unread}
              </Badge>
            </div>
            <MdNotifications
              size={25}
              color="primary"
              style={{ marginRight: '5px' }}
            />
          </DropdownToggle>
          {data?.length !== 0 ? (
            <DropdownMenu
              style={{
                position: 'relative',
              }}
              end
              id="dropDownMenu"
            >
              <div
                title="Temizle"
                onClick={() => {
                  deleteAll.mutate({ device_id: data[0].device_id })
                }}
                style={{
                  cursor: 'pointer',
                  padding: '5px',
                  borderRadius: '50%',
                  backgroundColor: 'white',
                  zIndex: 2,
                  position: 'absolute',
                  top: -10,
                  left: -10,
                }}
              >
                <Trash className="text-error" size={35} />
              </div>
              <div
                ref={containerRef}
                onScroll={handleScroll}
                style={{
                  width: '300px',
                  maxHeight: '230px',
                  overflowY: 'scroll',
                  position: 'relative',
                }}
              >
                {data?.map((item, index) => (
                  <Link to={'/whatsapp/received'} state={item}>
                    <DropdownItem
                      key={index}
                      id={`notificationDropItem-${item.ID}`}
                      style={{
                        whiteSpace: 'normal !important',
                        width: '100%',
                        backgroundColor:
                          item.is_read === 1 ? 'white' : '#F4F4F4',
                      }}
                    >
                      <Row
                        className="d-flex justify-content-around "
                        style={{ position: 'relative' }}
                      >
                        <Col xs={'2'}>
                          <Avatar
                            //img={'https://www.svgrepo.com/show/70698/avatar.svg'}
                            img={`/image/avatars/avatar-5.png`}
                            imgHeight="40"
                            imgWidth="40"
                            /*onClick={() => {
                              navigate(
                                `/whatsapp/received?message=${item.message_id}`
                              )
                            }}*/
                          />
                        </Col>
                        <Col xs={'10'} style={{ fontSize: '13px' }}>
                          <span
                            style={{
                              height: '3em',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: 'vertical',
                            }}
                            className="align-middle"
                          >
                            {item.message}
                          </span>
                        </Col>
                        <Col
                          style={{
                            textAlign: 'right',
                            fontSize: '10px',
                            position: 'absolute',
                            bottom: '-8px',
                          }}
                        >
                          <span className="align-middle">
                            {item.from_name
                              ? item.from_name
                              : `+${item.from.replace('@s.whatsapp.net', '')}`}
                          </span>
                        </Col>
                      </Row>
                    </DropdownItem>
                    <UncontrolledTooltip
                      target={`notificationDropItem-${item.ID}`}
                      placement={'bottom'}
                    >
                      <div>{item.message}</div>
                    </UncontrolledTooltip>
                  </Link>
                ))}
                {isLoading && (
                  <span
                    style={{
                      width: '100%',
                      margin: '20px',
                      textAlign: 'center',
                    }}
                  >
                    <p>{t('Bekleniyor')}...</p>
                  </span>
                )}
                <div
                  onClick={loadMoreData}
                  style={{
                    textAlign: 'center',
                    cursor: 'pointer',
                    margin: '10px 0',
                  }}
                >
                 {t('Yüklemeye devam et...')}
                </div>
              </div>
            </DropdownMenu>
          ) : (
            <DropdownMenu
              end
              style={{
                width: '300px',
              }}
            >
              <div ref={containerRef} onScroll={handleScroll}>
                <DropdownItem
                  id="notificationDropItem"
                  style={{ whiteSpace: 'normal !important', width: '100%' }}
                >
                  <Row
                    className="d-flex justify-content-around "
                    style={{ position: 'relative' }}
                  >
                    <Col
                      xs={'12'}
                      style={{
                        fontSize: '16px',
                        fontWeight: '700',
                        textAlign: 'center',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                      }}
                    >
                      <span className="align-center">
                        {t('Mesaj Bulunamadı')}
                      </span>
                    </Col>
                  </Row>
                </DropdownItem>
              </div>
            </DropdownMenu>
          )}
        </UncontrolledDropdown>
      </NavItem>
      {/* <NavItem className="d-none d-lg-block">
        <NavLink className="nav-link-style">
          <ThemeToggler />
        </NavLink>
      </NavItem> */}
      {/*<NavbarSearch />*/}
      {/*<CartDropdown />*/}
      {/*<NotificationDropdown />*/}
      <UserDropdown />
    </ul>
  )
}
export default NavbarUser
