// ** React Imports
import { Fragment, useEffect, useState } from 'react'

// ** Custom Components
import NavbarUser from './NavbarUser'
import NavbarBookmarks from './NavbarBookmarks'
import { useNavigate } from 'react-router-dom'
import { whatsappDeviceOnlineHook } from 'src/views/pages/whatsapp/campaign/store/hook'
import { RxAvatar } from 'react-icons/rx'
import { Badge, UncontrolledTooltip } from 'reactstrap'
import { useQueryClient } from '@tanstack/react-query'
import { whatsappMessageUnreadHook } from 'src/views/pages/whatsapp/account/store/hook'

const ThemeNavbar = (props) => {
  const navigate = useNavigate()
  const [totalUnread, setTotalUnread] = useState(0)
  const [identifier, setIdentifier] = useState(0)
  const { skin, setSkin, setMenuVisibility } = props

  // useEffect(() => {
  //   if (localStorage.getItem("auth") === null) {
  //     navigate("/giris");
  //   }
  // });

  return (
    <Fragment>
      <div className="bookmark-wrapper d-flex align-items-center d-none">
        <NavbarBookmarks setMenuVisibility={setMenuVisibility} />
        {/* <div className="d-flex gap-1 p-0">
          {onlineDevice?.data?.length > 0 &&
            onlineDevice?.data?.map((item, index) => (
              <div className="d-flex flex-column ">
                <div
                  className={`d-flex justify-content-center  cursor-pointer `}
                  onClick={() => {
                    localStorage.setItem('activeDevice', item.identifier)
                    window.location.reload()
                  }}
                  id={`device-${index}`}
                >
                  <div style={{ position: 'relative' }}>
                    {item.profile_url ? (
                      <img
                        src={item.profile_url}
                        className={`rounded-circle`}
                        style={{
                          width: '48px',
                          borderRadius: '100%',
                          border:
                            item.identifier ===
                            queryClient.getQueryData(['activeDevice'])
                              ? '2px solid #28c76f'
                              : 'none',
                        }}
                        alt="profile"
                      />
                    ) : (
                      <RxAvatar
                        fontSize={50}
                        className={`rounded-circle ${
                          localStorage.getItem('activeDevice') === null &&
                          index === 0
                            ? 'border-success'
                            : null
                        }  ${
                          item.identifier ===
                            queryClient.getQueryData(['activeDevice']) &&
                          'border-success'
                        }`}
                      />
                    )}

                    <div
                      style={{
                        display: item.unread_count === 0 ? 'none' : 'initial',
                        //float: 'right',
                        right: parseInt(item.unread_count) >= 100 ? -15 : -4,
                        top: -5,
                        position: 'absolute',
                      }}
                    >
                      <Badge color="success" pill>
                        {parseInt(item.unread_count) >= 100
                          ? '+99'
                          : item.unread_count}
                      </Badge>
                    </div>
                  </div>

                  <UncontrolledTooltip
                    target={`device-${index}`}
                    placement={'top'}
                  >
                    {item.username === '' || !item.username
                      ? item.business_name
                      : item.username}
                  </UncontrolledTooltip>
                </div>
                <div
                  style={{
                    width: '90px',
                    textAlign: 'center',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {item.username === '' || !item.username
                    ? item.business_name
                    : item.username}
                </div>
              </div>
            ))}
        </div> */}
      </div>
      <NavbarUser skin={skin} unread={totalUnread} setSkin={setSkin} />
    </Fragment>
  )
}

export default ThemeNavbar
