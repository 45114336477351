import { lazy } from 'react'
import Main from '../../views/pages/main'
import Register from '../../views/pages/authentication/Register/register'
import ForgotPassword from 'src/views/pages/authentication/ForgotPassword/ForgotPassword'
import Design from '../../views/pages/Design'
import Raports from '../../views/pages/Raports'
import User from '../../views/pages/User'

const Login = lazy(() => import('../../views/pages/authentication/Login/Login'))

const AppRoutes = [
  {
    element: <Main />,
    path: '/',
    meta: {
      appLayout: true,
      className: 'email-application',
      publicRoute: true,
    },
  },
  {
    element: <Main />,
    path: '/:id',
    meta: {
      appLayout: true,
      className: 'email-application',
      publicRoute: true,
    },
  },
  {
    element: <Design />,
    path: '/design',
    meta: {
      appLayout: true,
      className: 'email-application',
      publicRoute: true,
    },
  },
  {
    element: <Raports />,
    path: '/raports',
    meta: {
      appLayout: true,
      className: 'email-application',
      publicRoute: true,
    },
  },
  {
    element: <User />,
    path: '/users',
    meta: {
      appLayout: true,
      className: 'email-application',
      publicRoute: true,
    },
  },
  {
    element: <ForgotPassword />,
    path: '/forgot-password',
    meta: {
      appLayout: true,
      publicRoute: true,
    },
  },
  {
    element: <Login />,
    path: '/login',
    meta: {
      appLayout: true,
      className: 'email-application',
      publicRoute: false,
    },
  },
  {
    element: <Register />,
    path: '/kayit',
    meta: {
      appLayout: true,
      publicRoute: false,
    },
  },
]

export default AppRoutes
