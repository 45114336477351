import React, { useEffect, useRef, useState } from 'react'
import { CardText, CardTitle, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import phone from '../../../assets/images/main/phone.png'
import Profile from './Profile'
import Page from './Page'
import image from '../../../assets/images/main/wp-background.png'
import './index.scss'
import { Aperture, Copy, Facebook, Instagram, Link, Mail, Map, User } from 'react-feather'
import { BiWorld } from 'react-icons/bi'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button, Card, Container, Typography } from '@mui/material'
import theme from 'src/theme'
import { BsWhatsapp } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import Gallery from './Gallery'
import Video from './Video'
import StickyBox from 'react-sticky-box'

const index = () => {
    const [value, setValue] = useState(1)
    const navigate = useNavigate()
    const [valueLinks, setValueLinks] = useState(1)
    const [valueShops, setValueShops] = useState(1)
    const [currentShape, setCurrentShape] = useState(0)
    const [imageShapes, setImageShapes]: any = useState([
        {
            id: 0,
            shape: 'oval',
            style: { height: '100px', width: '100px', borderRadius: '100%', textAlign: 'center', cursor: 'pointer' }
        },
        {
            id: 1,
            shape: 'square',
            style: { height: '100px', width: '100px', textAlign: 'center', cursor: 'pointer' }
        },
        {
            id: 2,
            shape: 'radius',
            style: { height: '100px', width: '100px', borderRadius: '20px', textAlign: 'center', cursor: 'pointer' }
        },
    ])

    const profileValidationSchema: any = Yup.object({
        image_style: Yup.string().required('Bu alan zorunludur'),
        profile_name: Yup.string().required('Bu alan zorunludur'),
        description: Yup.string().required('Bu alan zorunludur'),
        position: Yup.string().required('Bu alan zorunludur'),
    })

    const profileFormik: any = useFormik({
        initialValues: {
            image_style: '',
            profile_name: 'Test Deneme',
            description: 'Test Deneme İçerik Açıklama',
            position: ''
        },
        validationSchema: profileValidationSchema,
        onSubmit: (values) => {
            console.log(values)
        }
    })

    const [chatWidth, setChatWidth]: any = useState(undefined);
    const [sidebarTop, setSidebarTop]: any = useState(undefined);

    useEffect(() => {
        const chatEl: any = document?.querySelector('.sidebar')?.getBoundingClientRect();
        setChatWidth(chatEl.width);
        setSidebarTop(chatEl.top);
    }, []);

    useEffect(() => {
        if (!sidebarTop) return;

        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    }, [sidebarTop]);

    const isSticky = (e: any) => {
        const chatEl: any = document.querySelector('.sidebar');
        const scrollTop = window.scrollY;
        if (scrollTop >= sidebarTop - 10) {
            chatEl.classList.add('is-sticky');
        } else {
            chatEl.classList.remove('is-sticky');
        }
    };

    const containerRef: any = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [containerOffsetLeft, setContainerOffsetLeft] = useState(0);

    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
                setContainerOffsetLeft(containerRef.current.getBoundingClientRect().left);
            }
        };

        window.addEventListener('resize', updateDimensions);
        updateDimensions();

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    return (
        <Container maxWidth='lg'>
            <Row className='d-flex justify-content-between align-items-start' style={{ position: 'relative' }}>
                <Col xs={12}>
                    <Row className='d-flex justify-content-start align-items-center'>
                        <Col xs={12} sm={12} md={12} lg={9} xl={9}>
                            <Card variant='elevation' elevation={0} style={{ backgroundColor: theme.palette.primary.main, color: 'white', borderRadius: '100vh' }}>
                                <Row className='d-flex justify-content-center align-items-center p-2' style={{ textAlign: 'center' }}>
                                    <span style={{ fontWeight: '700', cursor: 'pointer' }} onClick={() => navigate('/preview/123')}>
                                        Your Link Address: link.fb/test
                                        <Copy style={{ marginLeft: '10px' }} cursor={'pointer'} />
                                    </span>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} sm={12} md={12} lg={9} className='mt-2'>
                    <Nav tabs style={{ backgroundColor: '#0B3454', marginBottom: "0px", paddingTop: '15px', borderTopLeftRadius: '44px', borderTopRightRadius: '44px' }} >
                        <NavItem>
                            <NavLink
                                className=""
                                onClick={() => setValue(1)}
                            >
                                <div style={{ padding: "10px", color: value === 1 ? theme.palette.primary.main : 'white' }}>
                                    <Typography fontWeight={700}>
                                        <Link />
                                        Link
                                    </Typography>
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className=""
                                onClick={() => setValue(2)}
                            >
                                <div style={{ padding: "10px", color: value === 2 ? theme.palette.primary.main : 'white' }}>
                                    <Typography fontWeight={700}>
                                        <Aperture />
                                        Medya
                                    </Typography>
                                </div>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={value}>
                        <TabPane tabId={1}>
                            <Card variant='elevation' elevation={0} className='p-2' style={{ backgroundColor: '#FFF1E5', padding: '10px', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }} >
                                <div style={{ border: '2px solid white', borderRadius: '10px', padding: '20px' }}>
                                    <Nav tabs>
                                        <div style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'row', padding: '4px', borderRadius: '50px' }}>
                                            <NavItem>
                                                <NavLink
                                                    className=""
                                                    style={{ padding: '0px', margin: '0px' }}
                                                    onClick={() => setValueLinks(1)}
                                                >
                                                    <Button style={{ width: '150px', borderRadius: '50px', color: valueLinks === 1 ? 'white' : theme.palette.primary.main, backgroundColor: valueLinks === 1 ? theme.palette.primary.main : 'white' }} variant={valueLinks === 1 ? 'contained' : 'text'} color='secondary'>
                                                        Profil
                                                    </Button>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className=""
                                                    style={{ padding: '0px', margin: '0px' }}
                                                    onClick={() => setValueLinks(2)}
                                                >
                                                    <Button style={{ width: '150px', borderRadius: '50px', color: valueLinks === 2 ? 'white' : theme.palette.primary.main, backgroundColor: valueLinks === 2 ? theme.palette.primary.main : 'white' }} variant={valueLinks === 2 ? 'contained' : 'text'} color='secondary'>
                                                        Sayfa
                                                    </Button>
                                                </NavLink>
                                            </NavItem>
                                        </div>
                                    </Nav>
                                    <TabContent activeTab={valueLinks}>
                                        <TabPane tabId={1}>
                                            <Profile profileFormik={profileFormik} currentShape={currentShape} setCurrentShape={setCurrentShape} imageShapes={imageShapes} setImageShapes={setImageShapes} />
                                        </TabPane>
                                        <TabPane tabId={2}>
                                            <Page />
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </Card>
                        </TabPane>
                        <TabPane tabId={2}>
                            <Card variant='elevation' elevation={0} className='p-2' style={{ backgroundColor: '#FFF1E5', padding: '10px', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }} >
                                <div style={{ border: '2px solid white', borderRadius: '10px', padding: '20px' }}>
                                    <Nav tabs>
                                        <div style={{ width: '100%', backgroundColor: 'white', display: 'flex', flexDirection: 'row', padding: '4px', borderRadius: '50px' }}>
                                            <NavItem
                                                style={{ width: '100%' }}
                                            >
                                                <NavLink
                                                    className=""
                                                    style={{ padding: '0px', margin: '0px', width: '100%' }}
                                                    onClick={() => setValueShops(1)}
                                                >
                                                    <Button style={{ width: '100%', borderRadius: '50px', color: valueShops === 1 ? 'white' : theme.palette.primary.main, backgroundColor: valueShops === 1 ? theme.palette.primary.main : 'white' }} variant={valueShops === 1 ? 'contained' : 'text'} color='secondary'>
                                                        Galeri
                                                    </Button>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem
                                                style={{ width: '100%' }}
                                            >
                                                <NavLink
                                                    className=""
                                                    style={{ padding: '0px', margin: '0px', width: '100%' }}
                                                    onClick={() => setValueShops(2)}
                                                >
                                                    <Button style={{ width: '100%', borderRadius: '50px', color: valueShops === 2 ? 'white' : theme.palette.primary.main, backgroundColor: valueShops === 2 ? theme.palette.primary.main : 'white' }} variant={valueShops === 2 ? 'contained' : 'text'} color='secondary'>
                                                        Video
                                                    </Button>
                                                </NavLink>
                                            </NavItem>
                                        </div>
                                    </Nav>
                                    <TabContent activeTab={valueShops}>
                                        <TabPane tabId={1}>
                                            <Gallery />
                                        </TabPane>
                                        <TabPane tabId={2}>
                                            <Video />
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </Card>
                        </TabPane>
                    </TabContent>
                </Col>
                <Col xs={12} sm={12} md={12} lg={3} className='mt-5' style={{ height: '100%' }}>
                    <div style={{ width: '100%', position: 'relative', height: '100%' }} ref={containerRef}  >

                        <div className="phone-container d-none d-md-block sidebar" style={{ width: containerWidth ? `${containerWidth}px` : '100%', height: 'auto' }}>
                            <div>
                                <img width={'100%'} src={phone} alt="Phone" />
                            </div>
                            <div className="phone" style={{
                                display: 'flex', justifyContent: 'center',
                                //backgroundImage: `url(${phone})`,
                                marginTop: '15%',
                                backgroundRepeat: 'no-repeat', width: `${containerWidth}px`, backgroundSize: 'contain', height: '90%', overflowY: 'scroll'
                            }}>
                                <Row className='text-center' style={{ marginTop: '45px', height: '100%', justifyContent: 'flex-center', alignItems: 'center', width: '100%' }}>
                                    <Col xs={12}>
                                        <img src={image} alt='profile_image' style={imageShapes[currentShape].style} />
                                    </Col>
                                    <Col xs={12} className='mt-1'>
                                        <h4 style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            color: 'black'
                                        }}>
                                            @{profileFormik.values.profile_name}
                                        </h4>
                                    </Col>
                                    <Col xs={12}>
                                        <span style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            color: '#A7A8A9'
                                        }}>
                                            {profileFormik.values.description}
                                        </span>
                                    </Col>
                                    <Col xs={12}>
                                        <div className='d-flex gap-1 justify-content-center align-items-center'>
                                            <div className='mt-1'>
                                                <Mail color={theme.palette.primary.main} size={25} />
                                            </div>
                                            <div className='mt-1'>
                                                <BiWorld color={theme.palette.primary.main} size={25} />
                                            </div>
                                            <div className='mt-1'>
                                                <User color={theme.palette.primary.main} size={25} />
                                            </div>
                                            <div className='mt-1'>
                                                <Map color={theme.palette.primary.main} size={25} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} className='mt-2'>
                                        <Typography variant='h5' color={theme.palette.primary.main}>
                                            Text Başlık
                                        </Typography>
                                    </Col>
                                    <Col md={11} className='mt-1'>
                                        <Button fullWidth style={{ padding: '10px', paddingLeft: '20px', paddingRight: '20px', backgroundColor: theme.palette.primary.dark, borderRadius: '100px', display: 'flex', justifyContent: 'space-between' }} variant='contained' startIcon={<BsWhatsapp size={30} color={theme.palette.primary.main} />}>
                                            <span>
                                                Whatsapp
                                            </span>
                                        </Button>
                                    </Col>
                                    <Col md={11} className='mt-1'>
                                        <Button fullWidth style={{ padding: '10px', paddingLeft: '20px', paddingRight: '20px', backgroundColor: theme.palette.primary.dark, borderRadius: '100px', display: 'flex', justifyContent: 'space-between' }} variant='contained' startIcon={<Mail size={30} color={theme.palette.primary.main} />}>
                                            <span>
                                                Email
                                            </span>
                                        </Button>
                                    </Col>
                                    <Col md={11} className='mt-1'>
                                        <Button fullWidth style={{ padding: '10px', paddingLeft: '20px', paddingRight: '20px', backgroundColor: theme.palette.primary.dark, borderRadius: '100px', display: 'flex', justifyContent: 'space-between' }} variant='contained' startIcon={<Instagram size={30} color={theme.palette.primary.main} />}>
                                            <span>
                                                Instagram
                                            </span>
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row >
        </Container>
    )
}

export default index