// ** React Imports
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Row, Col, CardTitle, CardText, Label, Spinner, Input } from 'reactstrap'
// ** Styles
import '@styles/react/pages/page-authentication.scss'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  emailMess,
  maxPhoneLength,
  minLength,
  minPhoneLength,
  RqErrorMessage,
} from '../../../../helpers/RequiredMessage'
import formatNumber from '../../../../functions/Number/formatNumber'
import { useTranslation } from 'react-i18next'
import logo from 'src/assets/images/logo/vayonet-logo.png'
import CustomInput from '../../../components/customInput/CustomInput'
import { useSkin } from '../../../../utility/hooks/useSkin'
import { IRegister } from './store/model'
import { registerHook } from './store/hook'
import CustomSelect from 'src/views/components/customSelect/customSelect'
import timeZones from "../../../../lib/timezones.json"
import PhoneInput from 'react-phone-input-2'
import moment from 'moment'
import ReCAPTCHA from 'react-google-recaptcha'
import { getUserIpInfo } from '../../profile/store/service'
import Verification from 'src/views/components/smsModal/SmsModal'
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material'
import { GoogleLogin } from 'react-google-login'
import AppleSignin from 'react-apple-signin-auth'
import { FaApple } from 'react-icons/fa'
import { FcGoogle } from "react-icons/fc"
import theme from 'src/theme'
import { MdVisibilityOff } from 'react-icons/md'

const Register = () => {
  const [sendModal, setSendModal] = useState(false)
  const { skin } = useSkin()
  const { t }: any = useTranslation()
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  // const dispatch = useAppDispatch()
  // const isRegister = useAppSelector((state) => state.registerSlice.isRegister)

  const validationSchema = Yup.object({
    email: Yup.string().email(emailMess).required(t('Bu alan zorunludur*')),
    username: Yup.string().required(t('Bu alan zorunludur*')),
  })

  const initialValues: any = {
    username: '',
    email: '',
  }

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } =
    useFormik({
      initialValues: initialValues,
      validationSchema,
      onSubmit: (values) => {
        console.log(values)
      },
    })
  // useEffect(() => {
  //   if (registerMutation.isSuccess) {
  //     navigate('/giris')
  //   }
  // }, [registerMutation.isSuccess])
  // useEffect(() => {
  //   getUserIpInfo().then((res): any => setFieldValue("phone", res?.country_calling_code))
  // }, [])
  const source = require(`src/assets/images/pages/register_side.png`).default
  return (
    <div className="auth-wrapper auth-cover">

      <Row className="auth-inner m-0">
        <Link className="brand-logo cursor-pointer" to="/register">
          <img src='/image/logo.png' alt="logo" height={60} />
        </Link>

        <Col
          className="d-flex align-items-center auth-bg px-2 p-lg-5"
          lg="5"
          sm="12"
        >
          <Col className="px-xl-2 mx-auto text-center mt-sm-3" sm="8" md="6" lg="12">
            <Typography variant='h3' fontWeight={900} color={'#1A1A1A'}>
              {t("Join Us !")}
            </Typography>
            <Typography variant='subtitle1' className="mb-3">
              {t("Kayıt ol")}
            </Typography>

            <form className="auth-login-form mt-2" onSubmit={handleSubmit}>
              <div className="mb-1 col-md-12">
                <OutlinedInput
                  fullWidth
                  placeholder='Kullanıcı Adı'
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                  onKeyDown={(e: any) => {
                    if (!/[A-Za-z]/g.test(e.key)) {
                      e.preventDefault()
                    }
                  }}
                  sx={{ border: `1px solid #d8d6de` }}
                />
                {RqErrorMessage(errors.username, touched.username)}
              </div>
              <div className="mb-5">
                <OutlinedInput
                  fullWidth
                  placeholder='Email'
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  sx={{ border: `1px solid #d8d6de` }}
                />
                {RqErrorMessage(errors.email, touched.email)}
              </div>
              {/* <div className=''>
                <ReCAPTCHA
                  style={{ width: "100%" }}
                  className="mb-2"
                  sitekey={"6LdfjHopAAAAAAQhW3aCIe7xlanLihSxFwV6aK97"}
                  onChange={(e: any) => e?.length > 0 && setFieldValue("recaptcha", true)}
                />
                {RqErrorMessage(errors.recaptcha, touched.recaptcha)}
              </div> */}
              <Button color="primary" variant='contained' fullWidth style={{ marginTop: '23px', borderRadius: '100px', padding: '12px', fontWeight: '700', color: 'white' }}>
                {t("Kayıt Ol")}
              </Button>
            </form>

            <Typography variant='subtitle2' color={'#A7A8A9'} className='mt-2'>
              <strong style={{ color: 'black' }}>Hesap oluştur</strong>`a tıklayarak linkgo`nun <text style={{ textDecoration: 'underline' }}>Hüküm ve Koşullarını</text> kabul etmiş ve Gizlilik Bildirimimizi okuduğunuzu onaylamış olursunuz. Bizden teklifler, haberler ve güncellemeler alabilirsiniz.
            </Typography>
            <GoogleLogin
              clientId={''}
              render={(renderProps) => (
                <Button className='mt-5' startIcon={<FcGoogle size={25} />} color='secondary' variant='outlined' fullWidth style={{ textAlign: 'center', borderRadius: '100px', padding: '12px', fontWeight: '700' }}>
                  {t("Google ile Kayıt ol")}
                </Button>
              )}
              onSuccess={() => console.log('Başarılı giriş')}
              onFailure={() => console.log('Başarısız giriş')}
              cookiePolicy={'single_host_origin'}
            />
            <AppleSignin
              authOptions={{
                clientId: 'com.your.webservice',
                scope: 'email name',
                redirectURI: 'https://example.com',
                state: 'state',
                nonce: 'nonce',
                usePopup: true,
              }}
              uiType='light'
              noDefaultStyle={true}
              onSuccess={(response: any) => console.log(response)}
              onError={(error: any) => console.log(error)}
              render={(props: any) => (
                <Button className='mt-2' startIcon={<FaApple color='#A8C2AB' size={25} />} color='secondary' variant='outlined' fullWidth style={{ textAlign: 'center', borderRadius: '100px', padding: '12px', fontWeight: '700' }}>
                  {t("Apple ile Kayıt Ol")}
                </Button>
              )}
            />
            <p className="text-center mt-2">
              <span className="me-25">{t("Zaten Hesabınız var mı?")}</span>
              <Link to="/login">
                <span style={{ color: theme.palette.primary.main }}>{t("Giriş Yap")}</span>
              </Link>
            </p>
          </Col>
        </Col>
        <Col className="d-none d-lg-flex align-items-center" style={{ backgroundColor: theme.palette.primary.main }} lg="7" sm="12">
          <div className="w-100 d-lg-flex align-items-center justify-content-center" style={{ height: '100%' }}>
            <img src={source} alt="Login Cover" style={{ objectFit: 'cover' }} />
          </div>
        </Col>
      </Row>
      <Verification
        codeModal={sendModal}
        setCodeModal={setSendModal}
        deleteDispatch={(e: any) => console.log(e.code)}
        isSended={true}
        sendSmsDispatch
      />
    </div>
  )
}

export default Register
