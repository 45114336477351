import { FormControlLabel, Radio } from '@mui/material'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Check } from 'react-feather'
import { Card, CardBody, Col, Input, Row } from 'reactstrap'
import theme from 'src/theme'
import * as Yup from 'yup'
const index = (props: any) => {
    const { IconFormik } = props
    const [shape, setCurrentShape] = useState<number>(0)

    return (

        <Row className='d-flex justify-content-around align-items-start'>
            <Col xs={12} sm={12} md={12} lg={7} className='mt-3'>
                <Row className='d-flex justify-content-center align-items-start'>
                    <Col xs={11}>
                        <h5 style={{ color: '#4C4C9A', fontWeight: '600' }}>Resim Stili</h5>
                        <Row className='d-flex justify-content-start mt-2' style={{ maxWidth: '375px' }}>
                            <Col xs={4}>
                                <Card variant='elevation' elevation={3} className='cursor-pointer' onClick={() => setCurrentShape(0)} style={{ height: '100px', width: '100px', borderRadius: '100%', textAlign: 'center' }} title='Oval' />
                            </Col>
                            <Col xs={4}>
                                <Card variant='elevation' elevation={3} className='cursor-pointer' onClick={() => setCurrentShape(1)} style={{ height: '100px', width: '100px', textAlign: 'center' }} title='Square' />
                            </Col>
                            <Col xs={4}>
                                <Card variant='elevation' elevation={3} className='cursor-pointer' onClick={() => setCurrentShape(2)} style={{ height: '100px', width: '100px', borderRadius: '20px', textAlign: 'center' }} title='Radius' />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={11} className='mt-2'>
                        <h5 style={{ color: '#4C4C9A', fontWeight: '600' }}>İkon Yerleşimi</h5>
                        <Row className='mt-1 gap-2' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <Col xs={12} sm={9} onClick={() => IconFormik.setFieldValue('card_position', 1)} style={{ cursor: 'pointer', border: IconFormik.values.card_position === 1 ? `2px solid ${theme.palette.primary.main}` : '2px solid transparent', backgroundColor: '#F4F4F4', padding: '10px', borderRadius: '10px', height: '150px' }}>
                                <Row className='d-flex justify-content-start h-100'>
                                    <Col xs={4}>
                                        <Card variant='elevation' elevation={0} className='cursor-pointer' style={{ height: '100%', width: '100%', textAlign: 'center' }} title='Square' />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={9} onClick={() => IconFormik.setFieldValue('card_position', 2)} style={{ cursor: 'pointer', border: IconFormik.values.card_position === 2 ? `2px solid ${theme.palette.primary.main}` : '2px solid transparent', backgroundColor: '#F4F4F4', padding: '10px', borderRadius: '10px', height: '150px' }}>
                                <Row className='d-flex justify-content-start h-100'>
                                    <Col xs={4}>
                                        <Card variant='elevation' elevation={0} className='cursor-pointer' style={{ height: '100%', width: '100%', textAlign: 'center' }} title='Square' />
                                    </Col>
                                    <Col xs={4}>
                                        <Card variant='elevation' elevation={0} className='cursor-pointer' style={{ height: '100%', width: '100%', textAlign: 'center' }} title='Square' />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={9} onClick={() => IconFormik.setFieldValue('card_position', 3)} style={{ cursor: 'pointer', border: IconFormik.values.card_position === 3 ? `2px solid ${theme.palette.primary.main}` : '2px solid transparent', backgroundColor: '#F4F4F4', padding: '10px', borderRadius: '10px', height: '150px' }}>
                                <Row className='d-flex justify-content-around h-100'>
                                    <Col xs={4}>
                                        <Card variant='elevation' elevation={0} className='cursor-pointer' style={{ height: '100%', width: '100%', textAlign: 'center' }} title='Square' />
                                    </Col>
                                    <Col xs={4}>
                                        <Card variant='elevation' elevation={0} className='cursor-pointer' style={{ height: '100%', width: '100%', textAlign: 'center' }} title='Square' />
                                    </Col>
                                    <Col xs={4}>
                                        <Card variant='elevation' elevation={0} className='cursor-pointer' style={{ height: '100%', width: '100%', textAlign: 'center' }} title='Square' />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={11} className='mt-2'>
                        <h5 style={{ color: '#4C4C9A', fontWeight: '600' }}>Buton Rengi</h5>
                        <div className='mt-1 d-flex justify-content-start gap-1'>
                            <FormControlLabel value="ontop" control={<Radio />} label="Üstte" />
                            <FormControlLabel value="under" control={<Radio />} label="Altta" />
                            <FormControlLabel value="right" control={<Radio />} label="Sağda" />
                            <FormControlLabel value="left" control={<Radio />} label="Solda" />
                            <FormControlLabel value="upside" control={<Radio />} label="Üzerinde" />
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={5} className='mt-3'>
                <Row className='d-flex justify-content-center align-items-start'>
                    <Col xs={11} className=''>
                        <h5 style={{ color: '#4C4C9A', fontWeight: '600' }}>Buton Rengi</h5>
                        <div className='mt-1 d-flex justify-content-start gap-1'>
                            <div
                                onClick={() => IconFormik.setFieldValue('btn_color', 1)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#00AB55', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.btn_color === 1 ? <Check size={20} color='white' /> : null}
                            </div>
                            <div
                                onClick={() => IconFormik.setFieldValue('btn_color', 2)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#00B8D9', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.btn_color === 2 ? <Check size={20} color='white' /> : null}
                            </div>
                            <div
                                onClick={() => IconFormik.setFieldValue('btn_color', 3)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#003768', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.btn_color === 3 ? <Check size={20} color='white' /> : null}
                            </div>
                            <div
                                onClick={() => IconFormik.setFieldValue('btn_color', 4)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#FFAB00', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.btn_color === 4 ? <Check size={20} color='white' /> : null}
                            </div>
                            <div
                                onClick={() => IconFormik.setFieldValue('btn_color', 5)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#FFAC82', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.btn_color === 5 ? <Check size={20} color='white' /> : null}
                            </div>
                            <div
                                onClick={() => IconFormik.setFieldValue('btn_color', 6)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#B71D18', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.btn_color === 6 ? <Check size={20} color='white' /> : null}
                            </div>
                        </div>
                    </Col>
                    <Col xs={11} className='mt-4'>
                        <h5 style={{ color: '#4C4C9A', fontWeight: '600' }}>Buton Yazı Rengi</h5>
                        <div className='mt-1 d-flex justify-content-start gap-1'>
                            <div
                                onClick={() => IconFormik.setFieldValue('btn_text_color', 1)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#00AB55', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.btn_text_color === 1 ? <Check size={20} color='white' /> : null}
                            </div>
                            <div
                                onClick={() => IconFormik.setFieldValue('btn_text_color', 2)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#00B8D9', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.btn_text_color === 2 ? <Check size={20} color='white' /> : null}
                            </div>
                            <div
                                onClick={() => IconFormik.setFieldValue('btn_text_color', 3)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#003768', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.btn_text_color === 3 ? <Check size={20} color='white' /> : null}
                            </div>
                            <div
                                onClick={() => IconFormik.setFieldValue('btn_text_color', 4)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#FFAB00', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.btn_text_color === 4 ? <Check size={20} color='white' /> : null}
                            </div>
                            <div
                                onClick={() => IconFormik.setFieldValue('btn_text_color', 5)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#FFAC82', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.btn_text_color === 5 ? <Check size={20} color='white' /> : null}
                            </div>
                            <div
                                onClick={() => IconFormik.setFieldValue('btn_text_color', 6)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#B71D18', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.btn_text_color === 6 ? <Check size={20} color='white' /> : null}
                            </div>
                        </div>
                    </Col>
                    <Col xs={11} className='mt-4'>
                        <h5>
                            Buton Yazı Tipi
                        </h5>
                    </Col>
                    <Col xs={11} className='mt-4'>
                        <h5 style={{ color: '#4C4C9A', fontWeight: '600' }}>Yazı Rengi</h5>
                        <div className='mt-1 d-flex justify-content-start gap-1'>
                            <div
                                onClick={() => IconFormik.setFieldValue('text_color', 1)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#00AB55', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.text_color === 1 ? <Check size={20} color='white' /> : null}
                            </div>
                            <div
                                onClick={() => IconFormik.setFieldValue('text_color', 2)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#00B8D9', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.text_color === 2 ? <Check size={20} color='white' /> : null}
                            </div>
                            <div
                                onClick={() => IconFormik.setFieldValue('text_color', 3)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#003768', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.text_color === 3 ? <Check size={20} color='white' /> : null}
                            </div>
                            <div
                                onClick={() => IconFormik.setFieldValue('text_color', 4)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#FFAB00', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.text_color === 4 ? <Check size={20} color='white' /> : null}
                            </div>
                            <div
                                onClick={() => IconFormik.setFieldValue('text_color', 5)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#FFAC82', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.text_color === 5 ? <Check size={20} color='white' /> : null}
                            </div>
                            <div
                                onClick={() => IconFormik.setFieldValue('text_color', 6)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#B71D18', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.text_color === 6 ? <Check size={20} color='white' /> : null}
                            </div>
                        </div>
                    </Col>
                    <Col xs={11} className='mt-4'>
                        <h5 style={{ color: '#4C4C9A', fontWeight: '600' }}>Arka Plan Rengi</h5>
                        <div className='mt-1 d-flex justify-content-start gap-1'>
                            <div
                                onClick={() => IconFormik.setFieldValue('bg_color', 1)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#00AB55', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.bg_color === 1 ? <Check size={20} color='white' /> : null}
                            </div>
                            <div
                                onClick={() => IconFormik.setFieldValue('bg_color', 2)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#00B8D9', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.bg_color === 2 ? <Check size={20} color='white' /> : null}
                            </div>
                            <div
                                onClick={() => IconFormik.setFieldValue('bg_color', 3)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#003768', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.bg_color === 3 ? <Check size={20} color='white' /> : null}
                            </div>
                            <div
                                onClick={() => IconFormik.setFieldValue('bg_color', 4)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#FFAB00', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.bg_color === 4 ? <Check size={20} color='white' /> : null}
                            </div>
                            <div
                                onClick={() => IconFormik.setFieldValue('bg_color', 5)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#FFAC82', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.bg_color === 5 ? <Check size={20} color='white' /> : null}
                            </div>
                            <div
                                onClick={() => IconFormik.setFieldValue('bg_color', 6)}
                                style={{ height: '25px', width: '25px', backgroundColor: '#B71D18', borderRadius: '100%', cursor: 'pointer', textAlign: 'center' }}
                            >
                                {IconFormik.values.bg_color === 6 ? <Check size={20} color='white' /> : null}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default index