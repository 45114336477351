import React, { useRef, useState } from 'react'
import { Col, Label, Row } from 'reactstrap'
import image from '../../../../assets/images/main/wp-background.png'
import { ArrowRight, Edit, Edit2, Mail, Menu, MoreVertical, PenTool, PlusCircle, User } from 'react-feather'
import { MdEmail } from 'react-icons/md'
import '../index.scss'
import { Button, Card, Grid, Input, OutlinedInput, Radio, Switch, SwitchProps, TextField, styled } from '@mui/material'
import theme from 'src/theme'
import { IOSSwitch } from 'src/views/components/IOSSwitch'

const Index = (props: any) => {
    const { profileFormik, imageShapes, currentShape, setCurrentShape, setImageShapes } = props
    const fileRef: any = useRef()
    const [imageUrl, setImageUrl] = useState('');

    const handleImageChange = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImageUrl(imageUrl);
        }
    };

    return (
        <Row className='d-flex justify-content-around align-items-start direction-row m-lg-0 m-md-4 m-sm-4 m-xs-1'>
            <Col xs={12} sm={12} md={12} lg={7} xl={5}>
                <Row className='d-flex justify-content-center align-items-center direction-column g-5'>
                    <Col xs={12}>
                        <div className='d-flex justify-content-start align-items-end text-center gap-1'>
                            <div style={{ width: '100px' }}>
                                <Card variant='elevation' elevation={3} style={imageShapes[currentShape].style} >
                                    {imageUrl ? <img src={imageUrl} alt='profile_image' style={{ width: '100%', height: '100%' }} /> : null}
                                </Card>
                            </div>
                            <input ref={fileRef} onChange={handleImageChange} accept="image/png, image/jpeg" type='file' className='d-none' />
                            <Button variant='contained' onClick={() => fileRef.current.click()} style={{ fontWeight: '700', borderRadius: '5px', padding: '10px', paddingLeft: '15px', paddingRight: '15px', textAlign: 'center', color: '#434352', backgroundColor: theme.palette.primary.light }} >
                                <span style={{
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '130px'
                                }}>
                                    Dosya Seç
                                </span>
                            </Button>
                            <Button variant='contained' onClick={() => setImageUrl('')} style={{ textWrap: 'wrap', fontWeight: '700', borderRadius: '5px', padding: '10px', paddingLeft: '15px', paddingRight: '15px', textAlign: 'center', color: '#434352', backgroundColor: theme.palette.primary.light }} >
                                <span style={{
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '130px'
                                }}>
                                    Resim Kaldır
                                </span>
                            </Button>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <h6 style={{ color: '#4C4C9A', fontWeight: '600' }}>Resim Stili</h6>
                        <Row className='d-flex justify-content-center mt-2' style={{ maxWidth: '375px' }}>
                            <Col xs={12} sm={4} >
                                <Card variant='elevation' elevation={3} className='cursor-pointer' onClick={() => setCurrentShape(0)} style={{ height: '100px', width: '100px', borderRadius: '100%', textAlign: 'center' }} title='Oval' />
                            </Col>
                            <Col xs={12} sm={4} className='mt-xs-2'>
                                <Card variant='elevation' elevation={3} className='cursor-pointer' onClick={() => setCurrentShape(1)} style={{ height: '100px', width: '100px', textAlign: 'center' }} title='Square' />
                            </Col>
                            <Col xs={12} sm={4} className='mt-xs-2'>
                                <Card variant='elevation' elevation={3} className='cursor-pointer' onClick={() => setCurrentShape(2)} style={{ height: '100px', width: '100px', borderRadius: '20px', textAlign: 'center' }} title='Radius' />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12}>
                        <h6 style={{ color: '#4C4C9A', fontWeight: '600' }}>Profil Ünvanı</h6>
                        <OutlinedInput
                            style={{ width: '100%', border: '1px solid #DCE0E4', borderRadius: '5px', backgroundColor: 'white' }}
                            name='profile_name' value={profileFormik.values.profile_name} onChange={profileFormik.handleChange} placeholder='Profil adını giriniz...'
                            fullWidth
                        />
                        <h6 className='mt-3' style={{ color: '#4C4C9A', fontWeight: '600' }}>Hakkımızda</h6>
                        <TextField multiline variant='outlined' style={{ backgroundColor: 'white', width: '100%', border: '1px solid #DCE0E4', borderRadius: '5px' }} minRows={5} name='description' value={profileFormik.values.description} onChange={profileFormik.handleChange} placeholder='Açıklama adını giriniz...' type='textarea' />
                    </Col>
                </Row>
            </Col>

            <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                <Row className='d-flex justify-content-between align-items-center'>
                    <Col xs={12} sm={10}>
                        <h6 style={{ color: '#4C4C9A', fontWeight: '600' }}>
                            Profil İkonları
                        </h6>
                        <Row className='d-flex'>
                            <Col xs={12} className='pt-2 pb-2'>
                                <Grid container direction={'row'} gap={1} justifyContent={'space-between'} alignItems={'center'}>
                                    <Grid xs={9}>
                                        <OutlinedInput
                                            style={{ width: '100%', border: '1px solid #DCE0E4', borderRadius: '5px', backgroundColor: 'white' }}
                                            startAdornment={<MdEmail size={30} color={theme.palette.primary.main} style={{ marginRight: '10px' }} />}
                                            endAdornment={<Edit2 size={30} color={theme.palette.primary.main} style={{ marginLeft: '10px' }} cursor={'pointer'} />}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid xs={2}>
                                        <IOSSwitch />
                                    </Grid>
                                </Grid>
                            </Col>
                            <Col xs={12} className='d-flex align-items-center gap-1'>
                                <Button style={{ borderRadius: '5px', padding: '10px', textAlign: 'center', color: '#434352', backgroundColor: theme.palette.primary.light }} fullWidth variant='contained'>
                                    <PlusCircle cursor={'pointer'} />
                                    <span style={{ fontWeight: '600', cursor: 'pointer', marginLeft: '5px', marginTop: '3px' }}>İkon Ekle</span>
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col className='mt-4' xs={12} sm={10}>
                        <h6 style={{ color: '#4C4C9A', fontWeight: '600' }}>Konumu</h6>
                        <Row className='d-flex gap-1'>
                            <Col xs={12}>
                                <Radio />
                                <span style={{ marginLeft: '5px' }}>Üstte</span>
                            </Col>
                            <Col xs={12}>
                                <Radio />
                                <span style={{ marginLeft: '5px' }}>Altta</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col xs={12}>
                <Row className='d-flex justify-content-end mt-3'>
                    <Col xs={12} sm={4} md={3} lg={2}>
                        <Button fullWidth color='secondary' variant='contained' style={{ padding: '11px', borderRadius: '5px' }} >
                            İlerle
                            <ArrowRight style={{ marginLeft: '5px' }} size={22} />
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Index
