import { createTheme, ThemeOptions } from '@mui/material/styles';

const defaultTheme = createTheme();

const theme: any = createTheme({
  palette: {
    primary: {
      main: '#F78620',
      light: '#FFC28B',
      dark: '#FFF1E5'
    },
    secondary: {
      main: '#231F20',
    }
  },
  typography: {
    fontFamily: 'Poppins',
  },
  components: {
    ...defaultTheme.components,
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: '8px',
          fontFamily: 'Poppins',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '8px 16px', // Burada padding değerlerini ihtiyacınıza göre ayarlayabilirsiniz
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          padding: '0px', // Menu kapsayıcısının padding'ini sıfırlayın
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '&.MuiList-padding': {
            padding: '0px', // List elemanının padding'ini sıfırlayın
          },
        },
      },
    },
  },
});

export default theme;
